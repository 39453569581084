// TOGGLE_NEW_LISTING_FLOW_ENABLED // TODO: remove this file

<script setup>
import { ref, computed } from 'vue';
import BaseEmptyList from '@base/components/BaseEmptyList.vue';
import { useListenForGaEvents, useValidateAccountActions, useToast, useRouter } from '@base/hooks';
import {
  FormFlowCardLayout,
  FormFlowListActions,
  FormFlowListSkeleton,
  FormFlowListHeader,
} from '@flow/components/form-flow';
import { useStore } from '@/store';
import { useI18n } from '@/locales';

defineProps({
  loading: {
    type: Boolean,
    default: true,
  },
});

const store = useStore();
const toast = useToast();
const router = useRouter();
const { t } = useI18n();
const root = ref();
const modalShowed = ref(null);

useListenForGaEvents(root, 'automation-form-flows-app');

const { validateAccountActions } = useValidateAccountActions();
const formFlows = computed(() => store.getters['formFlow/getFormFlows']);
const pagination = computed(() => store.getters['formFlow/getPagination']);
const account = computed(() => store.getters['info/getAccount']);

const handleEdit = (flow) => {
  if (validateAccountActions()) {
    router.push({ path: flow.links.edit });
  }
};

const handleNew = async () => {
  if (validateAccountActions()) {
    const { data } = await store.dispatch('formFlow/createFormFlow');
    handleEdit(data.flow);
  }
};

const handleSuccessMessage = (type) => {
  const message = t(`flowModule.list.notifications.${type}`);
  toast.success(message);
};

const handleCloseModal = () => {
  store.dispatch('formFlow/resetSelectedFormFlow');
  modalShowed.value = null;
};

const handleDelete = async () => {
  await store.dispatch('formFlow/deleteFormFlow');
  handleSuccessMessage('delete');
  handleCloseModal();
};

const handleDisable = async () => {
  await store.dispatch('formFlow/disableFormFlow');
  handleSuccessMessage('disable');
  handleCloseModal();
};

const handleOpenModal = async (modal, flow = null) => {
  if (flow) {
    store.dispatch('formFlow/setSelectedFormFlow', flow);
  }
  modalShowed.value = modal;
};

const handleDuplicate = async (flow) => {
  if (flow) store.dispatch('formFlow/setSelectedFormFlow', flow);
  await store.dispatch('formFlow/duplicateFormFlow');
  toast.success(t('flowModule.list.notifications.duplicated'));
};
</script>

<template>
  <section
    ref="root"
    :class="$style.wrapper"
  >
    <FormFlowListHeader
      data-testid="formFlowListHeader"
      @handle-new="handleNew"
    />

    <FormFlowListSkeleton
      v-if="loading"
      data-testid="formFlowListSkeleton"
    />
    <template v-else>
      <BaseEmptyList
        v-if="formFlows?.length === 0"
        icon="bolt"
        :title="$t('flowModule.list.empty')"
        data-testid="emptyListComponent"
      />
      <section
        v-else
        :class="$style.formFlows"
      >
        <XCard
          v-for="(item, index) in formFlows"
          :key="index"
          :class="$style.flowCard"
          elevation="low"
          :data-testid="`${_.camelCase(item.name)}FlowCard`"
        >
          <FormFlowCardLayout
            :current-user="account"
            :flow="item"
            :data-testid="`${_.camelCase(item.name)}FlowCardLayout`"
            @open-alert-modal="handleOpenModal('FlowAlertModal')"
            @open-spreadsheet-alert-modal="handleOpenModal('SpreadsheetFlowAlertModal')"
            @open-copy-link-modal="handleOpenModal('FormFlowCopyLinkModal', $event)"
            @open-disable-modal="handleOpenModal('FormFlowDisableModal', $event)"
            @open-delete-modal="handleOpenModal('FlowDeleteModal', $event)"
            @open-edit="handleEdit($event)"
            @open-privacy-modal="handleOpenModal('FormFlowPrivacyModal', $event)"
            @open-duplicate="handleDuplicate($event)"
          />
        </XCard>
      </section>
      <XPagination
        v-if="pagination?.pageCount > 1"
        :class="$style.containerPagination"
        align="center"
        :active-page="pagination?.page"
        :total-pages="pagination?.pageCount"
        :next-text="$t('general.action.next')"
        :prev-text="$t('general.action.previous')"
        data-testid="paginationContainer"
      />
    </template>
    <FormFlowListActions
      :modal-showed="modalShowed"
      data-testid="formFlowListActions"
      @close="handleCloseModal"
      @disable="handleDisable"
      @delete="handleDelete"
    />
  </section>
</template>

<style lang="scss" module>
.wrapper {
  margin: var(--space-large);

  .headerContent {
    display: flex;
    gap: var(--space-regular);
  }

  .formFlows {
    display: grid;
    align-items: stretch;
    grid-template-columns: 1fr;
    gap: var(--space-large);

    .flowCard {
      flex: 1;
    }

    @include breakpoint('extra-large') {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.containerPagination {
  margin-top: var(--space-regular);
}
</style>

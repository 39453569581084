<template>
  <XModal
    :is-open="showModal"
    data-testid="formFlowPrivacyModalContainer"
    @close="$emit('close')"
    @opened="getPermissions"
  >
    <template #header>
      {{ $t('flowModule.modalPrivacy.title') }}
    </template>
    <template #default>
      <div
        v-if="loading"
        :class="$style.bodyLoader"
        data-testid="modalLoader"
      >
        <XSkeletonLoader
          shape="rounded"
          height="40px"
          width="60%"
        />
        <XSkeletonLoader
          shape="rounded"
          height="40px"
          width="100%"
        />
      </div>
      <template v-else>
        <div>
          <p>
            {{ $t('flowModule.modalPrivacy.selectAccessType.label') }}
            <b>{{ flowData?.name }}</b>
          </p>

          <XSelect
            v-model="flowData.private"
            :items="flowPrivacySelectAccessType"
            full-width
            data-testid="selectPrivacyType"
            @input="emitGAEvent"
          />
        </div>
        <div
          v-if="flowData?.private"
          :class="$style.private"
          data-testid="flowDataPrivate"
        >
          <h5 :class="$style.title">
            {{ $t('flowModule.modalPrivacy.selectMembers.label') }}
          </h5>
          <div :class="$style.privateChecks">
            <XCheckbox
              v-for="(user, index) in flowData?.memberships"
              :key="index"
              v-model="user.access"
              :disabled="user.isAdmin || user.isFlowOperator"
              :data-testid="_.camelCase(user.name)"
            >
              <div v-if="user.isAdmin || user.isFlowOperator">
                {{ user.name }} ({{
                  $t(
                    `flowModule.modalPrivacy.selectMembers.${user.isAdmin ? 'admin' : 'operator'}`
                  )
                }})
                <XTooltip placement="right">
                  <template #activator>
                    <XIcon icon="question-circle" />
                  </template>
                  {{
                    $t(
                      `flowModule.modalPrivacy.selectMembers.${
                        user.isAdmin ? 'adminInfo' : 'operatorInfo'
                      }`
                    )
                  }}
                </XTooltip>
              </div>
              <div v-else>
                {{ user.name }}
              </div>
            </XCheckbox>
          </div>
        </div>
      </template>
    </template>
    <template #actions>
      <div :class="$style.actions">
        <XButton
          design="outlined"
          data-ga-event="button-cancel-modal-permissions-click"
          data-testid="cancelButton"
          @click="$emit('close')"
        >
          {{ $t('general.action.cancel') }}
        </XButton>

        <XButton
          :loading="isSubmiting || loading"
          data-ga-event="button-close-modal-permissions-click"
          data-testid="submitButton"
          @click="submitPermissions"
        >
          {{ $t('general.action.save') }}
        </XButton>
      </div>
    </template>
  </XModal>
</template>

<script>
import { useStore } from '@/store';
import { computed, defineComponent, ref } from 'vue';

import { useI18n } from '@/locales';
import { useGA, useToast } from '@base/hooks';

export default defineComponent({
  name: 'FormFlowPrivacyModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const store = useStore();
    const isSubmiting = ref(false);
    const { t } = useI18n();
    const toast = useToast();
    const flowPrivacySelectAccessType = [
      {
        text: t('flowModule.modalPrivacy.selectAccessType.public'),
        value: false,
      },
      {
        text: t('flowModule.modalPrivacy.selectAccessType.private'),
        value: true,
      },
    ];

    const loading = computed(() => store.getters['formFlow/getLoading']);
    const flowData = computed(() => store.getters['formFlow/getFlowPermissions']);

    const allowedMembers = computed(() =>
      flowData.value?.memberships.filter((item) => item.access).map((user) => user.id)
    );
    const handleAccessList = computed(() => (flowData.value?.private ? allowedMembers.value : []));

    const getPermissions = async () => {
      await store.dispatch('formFlow/getFormFlowPermissions');
    };

    const emitGAEvent = (isPrivateSelected) => {
      const eventLabel = isPrivateSelected ? 'users-selected' : 'all-users';
      useGA('event', 'list-modal-permissions-users-click', 'automation-form-flows-app', eventLabel);
    };

    const submitPermissions = async () => {
      const payload = {
        flow: {
          private: flowData.value?.private,
        },
        permissions: {
          memberships: handleAccessList.value,
        },
      };
      try {
        isSubmiting.value = true;
        await store.dispatch('formFlow/setFormFlowPrivacy', payload);
        emit('close');
        toast.success(t('flowModule.modalPrivacy.notification.updated'));
      } catch (error) {
        toast.error(t('flowModule.modalPrivacy.notification.error'));
      } finally {
        isSubmiting.value = false;
      }
    };

    return {
      loading,
      isSubmiting,
      flowPrivacySelectAccessType,
      flowData,
      getPermissions,
      emitGAEvent,
      submitPermissions,
    };
  },
});
</script>

<style lang="scss" module>
.bodyLoader {
  display: flex;
  flex-direction: column;
  gap: var(--space-regular);
}

.actions {
  display: flex;
  gap: var(--space-regular);
}

.private {
  margin-top: var(--space-regular);

  .title {
    text-transform: uppercase;
  }

  .privateChecks {
    display: flex;
    flex-direction: column;
    gap: var(--space-small-xx);
  }
}
</style>

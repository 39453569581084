<template>
  <main>
    <template v-if="isFormFlowEnabled">
      <!-- 
        TOGGLE_NEW_LISTING_FLOW_ENABLED
        TODO: remove FeatureFlag and keep template default
      -->
      <FeatureFlag name="toggleNewListingFlowEnabled">
        <FormFlowListContainer data-testid="formFlowListContainer" />
        <template #off>
          <FormFlowListContainerOld
            :loading="loading"
            data-testid="formFlowListContainer"
          />
        </template>
      </FeatureFlag>
    </template>
    <FlowDisabledContainer
      v-else
      type="default"
      data-testid="flowDisabledContainer"
    />
  </main>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue';

import { useFunctionalities } from '@base/hooks';
import { FEATURES } from '@base/constants';
import { useStore } from '@/store';

import FormFlowListContainerOld from '@flow/containers/form-flow/FormFlowListContainerOld/FormFlowListContainerOld.vue';
import { FormFlowListContainer } from '@flow/containers/form-flow';
import FlowDisabledContainer from '@flow/containers/disabled/FlowDisabledContainer.vue';

export default defineComponent({
  name: 'FormFlowListView',
  components: {
    FormFlowListContainerOld,
    FlowDisabledContainer,
    FormFlowListContainer,
  },

  setup() {
    const store = useStore();
    const { isEnabled } = useFunctionalities();
    // TOGGLE_NEW_LISTING_FLOW_ENABLED
    // TODO: remove loading
    const loading = ref(true);

    // TOGGLE_NEW_LISTING_FLOW_ENABLED
    // TODO: remove isToggleEnabled
    const isToggleEnabled = store.getters['featureFlags/isToggleEnabled'];
    const isFormFlowEnabled = computed(() => isEnabled(FEATURES.FORM_FLOW));

    // TOGGLE_NEW_LISTING_FLOW_ENABLED
    // TODO: remove this method
    const fetchFormFlows = async () => {
      loading.value = true;
      store.commit('formFlow/SET_LINKS', { self: window.location.href });
      await store.dispatch('formFlow/fetchFormFlows');
      loading.value = false;
    };

    onMounted(() => {
      // TOGGLE_NEW_LISTING_FLOW_ENABLED
      // TODO: remove if and inner code
      if (!isToggleEnabled('toggleNewListingFlowEnabled')) {
        fetchFormFlows();
      }
    });

    return {
      isFormFlowEnabled,
      loading,
    };
  },
});
</script>

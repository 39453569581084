export default {
  UPDATE_LINKS: (state, payload) => {
    state.links = { ...(state.links || {}), ...(payload || {}) };
  },
  SET_USER: (state, payload) => {
    state.user = payload || {};
  },
  UPDATE_USER: (state, payload) => {
    state.user = { ...(state.user || {}), ...(payload || {}) };
  },
};

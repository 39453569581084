import { useI18n } from '@/locales';
import { useStore } from '@/store';
import { useRouter, useToast, useValidateAccountActions } from '@base/hooks';
import { FORM_FLOW_MODAL_CONSTANTS, FORM_FLOW_ACTIONS_CONSTANTS } from '@flow/constants';
import { ref } from 'vue';

export function useFormFlowListActions() {
  const { validateAccountActions } = useValidateAccountActions();
  const router = useRouter();
  const store = useStore();
  const toast = useToast();
  const { t } = useI18n();

  const modalShowed = ref(null);

  const handleEdit = (flow) => {
    if (validateAccountActions()) {
      router.push({ path: flow.links.edit });
    }
  };

  const handleNew = async () => {
    if (validateAccountActions()) {
      const { data } = await store.dispatch('formFlow/createFormFlow');
      handleEdit(data.flow);
    }
  };

  const handleSuccessMessage = (type) => {
    const message = t(`flowModule.list.notifications.${type}`);
    toast.success(message);
  };

  const handleCloseModal = () => {
    store.dispatch('formFlow/resetSelectedFormFlow');
    modalShowed.value = null;
  };

  const handleDelete = async () => {
    await store.dispatch('formFlow/deleteFormFlow');
    handleSuccessMessage('delete');
    handleCloseModal();
  };

  const handleDisable = async () => {
    await store.dispatch('formFlow/disableFormFlow');
    handleSuccessMessage('disable');
    handleCloseModal();
  };

  const handleOpenModal = async (modal, flow = null) => {
    if (flow) {
      store.dispatch('formFlow/setSelectedFormFlow', flow);
    }
    modalShowed.value = modal;
  };

  const handleDuplicate = async (flow) => {
    if (flow) store.dispatch('formFlow/setSelectedFormFlow', flow);
    await store.dispatch('formFlow/duplicateFormFlow');
    toast.success(t('flowModule.list.notifications.duplicated'));
  };

  const handleClickOption = ({ option, flow }) => {
    switch (option) {
      case FORM_FLOW_ACTIONS_CONSTANTS.edit:
        if (flow.enabled) {
          handleOpenModal(FORM_FLOW_MODAL_CONSTANTS.formFlowEditEnabledModal, flow);
        } else {
          handleEdit(flow);
        }
        break;
      case FORM_FLOW_ACTIONS_CONSTANTS.copyFormLink:
        handleOpenModal(FORM_FLOW_MODAL_CONSTANTS.formFlowCopyLinkModal, flow);
        break;
      case FORM_FLOW_ACTIONS_CONSTANTS.disable:
        handleOpenModal(FORM_FLOW_MODAL_CONSTANTS.formFlowDisableModal, flow);
        break;
      case FORM_FLOW_ACTIONS_CONSTANTS.duplicate:
        handleDuplicate(flow);
        break;
      case FORM_FLOW_ACTIONS_CONSTANTS.privacy:
        handleOpenModal(FORM_FLOW_MODAL_CONSTANTS.formFlowPrivacyModal, flow);
        break;
      case FORM_FLOW_ACTIONS_CONSTANTS.info:
        router.push({ path: flow.links.self });
        break;
      case FORM_FLOW_ACTIONS_CONSTANTS.delete:
        handleOpenModal(FORM_FLOW_MODAL_CONSTANTS.flowDeleteModal, flow);
        break;
      default:
        break;
    }
  };

  return {
    modalShowed,
    handleEdit,
    handleNew,
    handleSuccessMessage,
    handleCloseModal,
    handleDelete,
    handleDisable,
    handleOpenModal,
    handleDuplicate,
    handleClickOption,
  };
}

// TODO: remove phone key when midas is ready

export default {
  dateFormat: 'DD/MM/YYYY',
  errors: {
    shortRequired: 'Campo obrigatório',
    requiredIf: 'O campo é obrigatório',
    required: 'O campo é obrigatório',
    phone: 'Informe um número de telefone válido',
    phoneMidas: 'Informe um número de telefone válido',
    shortPhone: 'Número inválido',
    cellPhone: 'Informe um celular válido',
    email: 'Informe um e-mail válido',
    cnpj: 'Informe um CNPJ válido',
    cpf: 'Informe um CPF válido',
    shortCpf: 'CPF inválido',
    date: 'Informe uma data válida',
    shortDate: 'Data inválida',
    fullName: 'Informe nome e sobrenome',
    dateInThePast: 'Deve ser anterior à data atual',
    ipCidr: 'Informe um IP CIDR válido',
    ipv4Cidr: '@:validation.errors.ipCidr',
    ipv6Cidr: '@:validation.errors.ipCidr',
    url: 'URL inválida',
    signAsRequired: 'Selecione como este signatário deve assinar',
    sameAsPassword: 'Confirmação de senha não confere.',
    sameAs: 'Os campos precisam ser correspondentes.',
    minLength: 'Por favor insira no mínimo {min} caracteres.',
    maxLength: 'Por favor insira no máximo {max} caracteres.',
    cep: 'Informe um CEP válido',
    fetchCep: 'CEP não encontrado',
    numeric: 'Valor inválido. Informe apenas números',
    currency: 'Informe um valor monetário válido, em Real',
    domain: 'Informe um domínio válido',
    hasNoSequentialCharacters: 'Não são permitidos caracteres sequenciais (123, abc)',
    cannotContainsName: 'Não é permitido utilizar seu nome ou sobrenome na senha',
    containsUppercase: 'Insira pelo menos um caractere maiúsculo',
    containsLowercase: 'Insira pelo menos um caractere minúsculo',
    containsNumber: 'Insira pelo menos um número',
    containsSpecial: 'Insira pelo menos um caractere especial',
    creditCardNumber: 'O cartão de crédito é inválido',
    expiredCreditCard: 'A data de validade está incorreta',
    creditCardName: 'O nome do titular do cartão é inválido',
    creditCardExpiration: 'A data de validade está incorreta',
    creditCardCvv: 'O código de sgurança está incorreto',
    creditCardNetwork: 'Bandeira não aceita. Use outro cartão',
    creditCardPastDate: 'Data inválida',
    hasEmoji: 'Não é permitido utilizar emojis',
  },
};

<template>
  <transition
    name="showBackgroundaAnimation"
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
  >
    <div
      v-show="value"
      ref="backdrop"
      :class="[$style.backdrop, { [$style.contentScrollable]: scrollable }, ...backdropClasses]"
      @mousedown.self="closeBackdrop"
    >
      <transition
        name="showModalAnimation"
        enter-active-class="animated slideInDown"
        leave-active-class="animated slideOutUp"
      >
        <div
          v-show="!loading && isVisible"
          :class="[
            $style.modal,
            elevation && '-elevation12',
            { [$style.contentScrollable]: scrollable },
            { [$style.fillHeight]: fillHeight },
            ...modalClasses,
          ]"
          :style="modalSize"
        >
          <header
            v-show="$slots.header || closeIcon"
            :class="[$style.header, ...headerClasses]"
          >
            <slot name="header" />
            <span
              v-show="closeIcon"
              @click="$emit('close')"
            >
              <XIcon
                icon="times"
                :class="$style.closeIcon"
              />
            </span>
          </header>

          <div
            :class="[$style.content, { [$style.contentScrollable]: scrollable }, ...contentClasses]"
          >
            <slot name="default" />
          </div>

          <footer
            v-show="$slots.actions"
            :class="[$style.actions, ...actionsClasses]"
          >
            <slot name="actions" />
          </footer>
        </div>
      </transition>

      <XSpinner v-show="loading" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseBackdropModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    backdropClasses: {
      type: Array,
      default: () => [],
    },
    modalClasses: {
      type: Array,
      default: () => [],
    },
    headerClasses: {
      type: Array,
      default: () => [],
    },
    contentClasses: {
      type: Array,
      default: () => [],
    },
    actionsClasses: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    closeIcon: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: '600',
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    isNested: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.value,
    };
  },
  computed: {
    modalSize() {
      return `width: ${this.width}px;`;
    },
  },
  watch: {
    value(v) {
      this.isVisible = this.value;
      const fn = () => {};
      const bodyClassList = this.isNested ? { add: fn, remove: fn } : document.body.classList;
      if (v) {
        bodyClassList.add(this.$style.removeScrollbar);
        this.$emit('opened');
      } else {
        bodyClassList.remove(this.$style.removeScrollbar);
      }
    },
  },
  beforeDestroy() {
    if (!this.isNested) {
      document.body.classList.remove(this.$style.removeScrollbar);
    }
  },
  methods: {
    hasScrollbar() {
      const backdropElement = this.$refs.backdrop;
      if (backdropElement) {
        return backdropElement.scrollHeight > backdropElement.clientHeight;
      }
      return false;
    },
    getScrollbarWidth() {
      const backdropElement = this.$refs.backdrop;
      if (backdropElement) {
        return backdropElement.offsetWidth - backdropElement.clientWidth;
      }
      return 0;
    },
    hitScrollbar({ offsetX }) {
      if (this.hasScrollbar()) {
        return window.innerWidth - this.getScrollbarWidth() <= offsetX;
      }
      return false;
    },
    closeBackdrop(event) {
      if (!this.persistent && !this.hitScrollbar(event)) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" module>
body {
  &.removeScrollbar {
    overflow: hidden;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bold);
  color: var(--color-neutral-800);
}

.closeIcon {
  margin-left: auto;
  font-size: var(--font-size-large);
  color: var(--color-neutral-1000);
  cursor: pointer;
}

.backdrop {
  position: fixed;
  z-index: var(--zindex-backdrop);
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  width: 100vw;
  height: 100vh;
  background: var(--color-transparent-1000-medium);
  animation-duration: var(--time-transition-base);

  &.contentScrollable {
    overflow: hidden;
  }
}

.modal {
  z-index: var(--zindex-modal);
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word; /* added to break very too long words */
  margin: var(--space-large);
  word-break: normal; /* keep words without break at line end */
  background: var(--color-neutral-0);
  border-radius: var(--border-radius-small);
  animation-duration: 0.5s;

  &.contentScrollable {
    overflow: hidden;
    max-height: calc(100vh - 64px);
  }

  &.fillHeight {
    height: calc(100vh - 64px);
  }

  > .header {
    padding: var(--space-medium) var(--space-medium) 0;
  }

  > .content {
    height: 100%;
    padding: var(--space-medium);

    &.contentScrollable {
      overflow: auto;
    }
  }

  > .actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 var(--space-medium) var(--space-medium);
  }
}
</style>

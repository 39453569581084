import masker from 'vue-the-mask/src/masker';
import tokens from 'vue-the-mask/src/tokens';

export const MASKS = {
  phone: ['(##) ####-####', '(##) #####-####'],
  phoneMX: ['##-####-####', '###-####-####'],
  date: ['##/##/####'],
  cpf: ['###.###.###-##'],
  cnpj: ['##.###.###/####-##'],
};

export const maskText = (text, mask, customTokens = undefined) => {
  const maskedText = masker(text, mask, true, customTokens || tokens);
  return maskedText;
};

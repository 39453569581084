<template>
  <XModal
    :is-open="showModal"
    data-testid="formFlowDeleteModalContainer"
    @close="$emit('close')"
  >
    <template #header>
      {{ $t('flowModule.modalFormFlowDelete.header') }}
    </template>
    <template #default>
      <p>
        {{ $t('flowModule.modalFormFlowDelete.title') }}
      </p>
    </template>
    <template #actions>
      <div :class="$style.actions">
        <XButton
          design="outlined"
          data-testid="modalDeleteCloseButton"
          @click="$emit('close')"
        >
          {{ $t('general.action.cancel') }}
        </XButton>
        <XButton
          data-testid="modalDeleteButtonSubmit"
          @click="$emit('delete')"
        >
          {{ $t('general.action.delete') }}
        </XButton>
      </div>
    </template>
  </XModal>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormFlowDeleteModal',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" module>
.actions {
  display: flex;
  gap: var(--space-regular);
}
</style>

import {
  createI18nMessage,
  helpers,
  requiredIf as validatorRequiredIf,
} from '@vuelidate/validators';

import i18n from '@/locales';
import * as validators from '.';

// TODO: remove phoneValidator function when midas is ready
const phoneValidator = helpers.regex(/(^[0-9]{2,3}\s)?\([0-9]{2}\)\s[0-9]{4,5}-[0-9]{4}$/);

// for vue-i18n@8 and bellow
const withI18nMessage = createI18nMessage({ t: i18n?.t?.bind(i18n) });
const resolveMsgPath = ({ $validator }) => `validation.errors.${$validator}`;

const defaultOptions = { messagePath: resolveMsgPath };

export const required = withI18nMessage(validators.required, defaultOptions);
export const requiredIf = withI18nMessage(validatorRequiredIf, {
  ...defaultOptions,
  withArguments: true,
});
// TODO: remove phone const when midas is ready
export const phone = withI18nMessage(phoneValidator, defaultOptions);
export const phoneMidas = withI18nMessage(validators.phoneMidas, defaultOptions);
export const phoneMx = withI18nMessage(validators.phoneMx, defaultOptions);
export const email = withI18nMessage(validators.email, defaultOptions);
export const cpf = withI18nMessage(validators.cpf, defaultOptions);
export const cnpj = withI18nMessage(validators.cnpj, defaultOptions);
export const date = withI18nMessage(validators.date, defaultOptions);
export const dateInThePast = withI18nMessage(validators.dateInThePast, defaultOptions);
export const fullName = withI18nMessage(validators.fullName, defaultOptions);
export const cellPhone = withI18nMessage(phoneValidator, defaultOptions);
export const cep = withI18nMessage(validators.cep, defaultOptions);
export const numeric = withI18nMessage(validators.numeric, defaultOptions);
export const containsUppercase = withI18nMessage(validators.containsUppercase, defaultOptions);
export const containsLowercase = withI18nMessage(validators.containsLowercase, defaultOptions);
export const containsNumber = withI18nMessage(validators.containsNumber, defaultOptions);
export const containsSpecial = withI18nMessage(validators.containsSpecial, defaultOptions);
export const ipv4Cidr = withI18nMessage(validators.ipv4Cidr, defaultOptions);
export const ipv6Cidr = withI18nMessage(validators.ipv6Cidr, defaultOptions);
export const ipCidr = withI18nMessage(validators.ipCidr, defaultOptions);
export const url = withI18nMessage(validators.url, defaultOptions);

export const fetchCepAsync = helpers.withAsync(validators.fetchCep);
export const fetchCep = withI18nMessage(fetchCepAsync, defaultOptions);

export const minLength = withI18nMessage(validators.minLength, {
  ...defaultOptions,
  withArguments: true,
});

export const maxLength = withI18nMessage(validators.maxLength, {
  ...defaultOptions,
  withArguments: true,
});

export const sameAs = withI18nMessage(validators.sameAs, {
  ...defaultOptions,
  withArguments: true,
});

// TODO: Fix maxLength validators
export const maxLengthMidas = (length) => helpers.withParams({ max: length }, maxLength(length));

export const hasNoSequentialCharacters = withI18nMessage(
  validators.hasNoSequentialCharacters,
  defaultOptions
);

export const creditCardNumber = withI18nMessage(validators.creditCardNumber, {
  ...defaultOptions,
  withArguments: true,
});

export const creditCardExpiration = withI18nMessage(validators.creditCardExpiration, {
  ...defaultOptions,
  withArguments: true,
});

export const creditCardCvv = withI18nMessage(validators.creditCardCVV, {
  ...defaultOptions,
  withArguments: true,
});

export const containsUpperAndLowercase = withI18nMessage(
  validators.containsUpperAndLowercase,
  defaultOptions
);

export const creditCardNetwork = withI18nMessage(validators.creditCardNetwork, defaultOptions);

export const creditCardPastDate = withI18nMessage(validators.creditCardPastDate, defaultOptions);

export const hasEmoji = withI18nMessage(validators.hasEmoji, defaultOptions);

const layout = {
  sidebar: {
    addDocuments: 'Agregar documentos',
    navigation: {
      dashboard: 'Inicio',
      dashboardDocumentDetails: 'Plazos',
      documents: 'Documentos',
      rootFolder: 'Todos',
      runningDocuments: 'En proceso',
      closedDocuments: 'Finalizados',
      canceledDocuments: 'Cancelados',
      downloads: 'Descargados',
      trashBin: 'Papelera',
      draftPacks: 'Borradores',
      trackingNotifications: 'Correos enviados',
      flowProcesses: 'Aprobación',
      contacts: 'Agenda de contactos',
      reports: 'Informes',
      automation: 'Automatización',
      newTemplate: 'Crear automatización',
      templates: 'Plantillas',
      formBuilders: 'Formularios',
      formFlows: 'Flujos con formulario',
      spreadsheetFlows: 'Flujos con hoja de cálculo',
      settings: 'Configuraciones',
      management: 'Gestión',
      acceptance: 'Aceptaciones',
      acceptanceTermWhatsappIndex: 'Por WhatsApp',
      acceptanceTermContentTemplates: 'Plantillas',
      pendingAnswers: 'Respuestas pendientes',
      // TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
      // TODO: Decide if help, faq, support and commercial should be removed
      help: '¿Necesitas ayuda?',
      faq: 'Preguntas frecuentes',
      support: 'Habla con soporte',
      commercial: 'Contacto con el Comercial',
    },
    needHelp: '¿Necesitas ayuda?',
    upgrade: 'Mejorar',
  },
  header: {
    search: {
      placeholder: 'Buscar por documento',
      action: 'Buscar',
      tip: 'Nombre del documento, nombre o correo del firmante',
      history: 'Últimas búsquedas',
      suggestion: 'Sugerencia de búsqueda',
      empty: 'No encontramos resultados para tu búsqueda.',
    },
    organizations: 'Organizaciones de las que formas parte',
    signerArea: 'Mi área de firma',
    signer: 'Documentos para firmar',
    editProfile: 'Configuraciones del perfil',
    helpCenter: 'Ayuda',
    signout: 'Cerrar sesión',
    closeLink: 'Salir',
    backToHome: 'Volver al inicio',
    continueTrial: 'Cerrar y probar gratis',
    headway: {
      title: 'Actualizaciones de la plataforma',
      readMore: 'Conoce más',
      footer: 'Historial de actualizaciones 👉',
    },
    filter: {
      title: 'Filtrar documentos',
      maintenance: {
        title: 'Filtros temporalmente no disponibles',
        description: 'Estamos trabajando para mejorar tu experiencia. ¡Gracias por tu comprensión!',
      },
      statusSelect: {
        label: 'Estatus',
        placeholder: 'Cualquier estatus',
      },
      status: {
        running: 'En proceso',
        closed: 'Finalizados',
        canceled: 'Cancelados',
      },
      documentationInput: 'Número de identificación fiscal del firmante',
      createdAt: 'Fecha de envío',
      deadlineAt: 'Fecha límite de firma',
      finishedAt: 'Fecha del documento finalizado',
      filterDateRange: {
        placeholder: 'Todo',
        tooltip: 'No aplicable a documentos en trámite',
      },
      buttons: {
        clear: 'Limpiar filtros',
        apply: 'Aplicar',
      },
      disabled: 'Deshabilitado',
    },
    dropdown: {
      signerArea: 'Mi área de firma',
      signerDashboard: 'Documentos para firmar',
      accounts: 'Cuentas',
      config: 'Configuraciones del perfil',
      logout: 'Cerrar sesión',
      createAccount: 'Crear cuenta',
    },
    helpForm: {
      title: 'Soporte',
    },
  },
  sandboxBanner: {
    title: 'Ambiente SANDBOX - Sin valor legal',
  },
  mobileAlert: {
    header: 'Ingresa desde un computadora',
    description: '¡Ingresa desde una computadora para aprovechar al máximo Clicksign!',
  },
};
export default layout;

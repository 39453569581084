<script setup>
import { ref } from 'vue';
import { useListenForGaEvents } from '@base/hooks';
import { FormFlowListHeader, FormFlowTableList } from '@flow/components/form-flow';
import { useFormFlowListActions } from '@flow/composables/useFormFlowListActions';
import { useI18n } from '@/locales';

const { t } = useI18n();

const root = ref();

useListenForGaEvents(root, 'automation-form-flows-app');

const tabItems = [
  { title: t('flowModule.list.tab.flow'), name: 'flow', to: { name: 'formFlowListFlows' } },
  {
    title: t('flowModule.list.tab.folders'),
    name: 'folder',
    to: { name: 'formFlowListFolders' },
  },
];

const { handleNew } = useFormFlowListActions();
</script>

<template>
  <section
    ref="root"
    :class="$style.wrapper"
  >
    <FormFlowListHeader
      data-testid="formFlowListHeader"
      @handle-new="handleNew"
    />
    <!--
      TOGGLE_FLOW_FOLDER_ENABLED
      TODO: remove feature flag and keep template default
    -->
    <FeatureFlag name="toggleFlowFolderEnabled">
      <template #default>
        <div>
          <XTabs
            :items="tabItems"
            :active-tab="$route.name === 'formFlowListFolders' ? 'folder' : 'flow'"
            :class="$style.tabs"
          />
          <router-view />
        </div>
      </template>
      <template #off>
        <FormFlowTableList />
      </template>
    </FeatureFlag>
  </section>
</template>

<style lang="scss" module>
.tabs {
  width: 100%;

  [role='tabpanel'] {
    display: none;
  }
}

.wrapper {
  margin: var(--space-large);
}
</style>

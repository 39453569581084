<script setup>
import { useI18n } from '@/locales';
import { useStore } from '@/store';
import { useRouter, useToast, useValidateAccountActions } from '@base/hooks';
import { useFormFlowPendingProcesses } from '@flow/composables';
import { computed, ref } from 'vue';

defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const {
  hasPendingProcesses,
  hasWaitingSecondFormProcesses,
  handleGoToProcesses,
  handleGoToPendingAnswers,
  verifyPendingProcesses,
  modalInvalidType,
  hasProcessesOrAnswers,
  loading,
} = useFormFlowPendingProcesses();
const { validateAccountActions } = useValidateAccountActions();
const store = useStore();
const { t } = useI18n();
const toast = useToast();
const router = useRouter();
const isSubmiting = ref(false);

const selectedFlow = computed(() => store.getters['formFlow/getSelectedFlow']);
const modalTitle = computed(() =>
  hasProcessesOrAnswers.value
    ? t(`flowModule.modalDisable.${modalInvalidType.value}.header`)
    : t('flowModule.modalEditEnabled.header')
);

const disableToEditFormFlow = async () => {
  if (validateAccountActions()) {
    try {
      isSubmiting.value = true;
      await store.dispatch('formFlow/disableFormFlow');
      router.push({ path: selectedFlow.value.links.edit });
      emit('close');
      toast.success(t('flowModule.modalEditEnabled.notification.success'));
    } catch (error) {
      toast.error(t('flowModule.modalEditEnabled.notification.error'));
    } finally {
      isSubmiting.value = false;
    }
  }
};

const handleSubmit = async () => {
  if (hasPendingProcesses.value) {
    handleGoToProcesses();
  } else if (hasWaitingSecondFormProcesses.value) {
    handleGoToPendingAnswers();
  } else {
    disableToEditFormFlow();
  }
};
</script>

<template>
  <XModal
    :is-open="showModal"
    :title="modalTitle"
    :loading="loading"
    data-testid="formFlowEditEnabledModalContainer"
    @close="$emit('close')"
    @opened="verifyPendingProcesses"
  >
    <template #default>
      <p>
        {{
          hasProcessesOrAnswers
            ? $t(`flowModule.modalDisable.${modalInvalidType}.title`)
            : $t('flowModule.modalEditEnabled.title')
        }}
      </p>
    </template>
    <template #actions>
      <div :class="$style.actions">
        <XButton
          design="outlined"
          data-testid="modalEditEnabledCloseButton"
          @click="$emit('close')"
        >
          {{ $t('general.action.cancel') }}
        </XButton>
        <XButton
          :loading="isSubmiting"
          data-testid="modalEditEnabledButtonSubmit"
          @click="handleSubmit"
        >
          {{
            hasProcessesOrAnswers
              ? $t(`flowModule.modalDisable.${modalInvalidType}.action`)
              : $t('flowModule.modalEditEnabled.action')
          }}
        </XButton>
      </div>
    </template>
  </XModal>
</template>

<style lang="scss" module>
.actions {
  display: flex;
  gap: var(--space-regular);
}
</style>

<script setup>
import { useI18n } from '@/locales';
import { useStore } from '@/store';
import { useRoute, useRouter } from '@base/hooks';
import { camelizeKeys } from '@base/utils';
import {
  FormFlowListFilters,
  FormFlowEmptyList,
  FormFlowEmptySearch,
  FormFlowTableItem,
  FormFlowListActions,
} from '@flow/components/form-flow';
import { useFormFlowListActions } from '@flow/composables/useFormFlowListActions';
import { computed, onMounted, ref } from 'vue';

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

const {
  modalShowed,
  handleNew,
  handleCloseModal,
  handleDelete,
  handleDisable,
  handleOpenModal,
  handleClickOption,
} = useFormFlowListActions();

const loading = ref(true);

const HEADERS = [
  {
    label: t('flowModule.list.table.name'),
    value: 'name',
    colspan: 6,
    sortable: true,
  },
  {
    label: t('flowModule.list.table.createdAt'),
    value: 'createdAt',
    sortable: true,
  },
  {
    label: t('flowModule.list.table.enabled'),
    value: 'enabled',
    sortable: true,
  },
  {
    value: 'action',
    colspan: 2,
  },
];

const formFlows = computed(() => store.getters['formFlow/getFormFlows']);
const totalItems = computed(() => store.getters['formFlow/getTotalItems']);
const pagination = computed(() => store.getters['formFlow/getPagination']);
const account = computed(() => store.getters['info/getAccount']);
const hasFilters = computed(() => {
  const { name, enabled } = route.query;
  const startDate = route.query.startDate ?? route.query.start_date;
  const endDate = route.query.endDate ?? route.query.end_date;

  return !!(name || enabled || startDate || endDate);
});

const ORDER_FIELDS_MAPPING = {
  name: 'name',
  createdAt: 'created_at',
  enabled: 'enabled',
};

const handleSorted = async (order, column) => {
  let orderDirection;

  if (ORDER_FIELDS_MAPPING[column.value] === route.query.orderBy) {
    orderDirection = route.query.orderDirection === 'asc' ? 'desc' : 'asc';
  }

  await router.replace({
    query: {
      ...camelizeKeys(route.query),
      orderBy: ORDER_FIELDS_MAPPING[column.value],
      orderDirection: orderDirection || order,
    },
  });
};

const fetchFormFlows = async () => {
  loading.value = true;
  store.commit('formFlow/SET_LINKS', { self: window.location.href });
  await store.dispatch('formFlow/fetchFormFlows');
  loading.value = false;
};

onMounted(() => {
  fetchFormFlows();
});
</script>

<template>
  <div>
    <XCard>
      <div
        v-if="totalItems != 0 || hasFilters"
        :class="$style.tableActions"
        data-testid="tableActions"
      >
        <FormFlowListFilters :total-items="totalItems" />
      </div>
      <template v-if="totalItems == 0 && !loading">
        <FormFlowEmptyList
          v-if="!hasFilters"
          icon="code-branch"
          :title="$t('flowModule.emptyList.title')"
          :description="$t('flowModule.emptyList.description')"
          :action-text="$t('flowModule.new')"
          data-testid="formFlowEmptyList"
          @handle-new="handleNew"
        />
        <FormFlowEmptySearch v-else />
      </template>
      <template v-else>
        <XTable
          :headers="HEADERS"
          :items="formFlows"
          :loading="loading"
          @sort="handleSorted"
        >
          <template #item="{ item, index, headers, gridTemplateColumns }">
            <FormFlowTableItem
              :item="item"
              :headers="headers"
              :grid-template-columns="gridTemplateColumns"
              :index="index"
              :account="account"
              :loading="loading"
              @open-modal="handleOpenModal"
              @click-option="handleClickOption"
            />
          </template>
        </XTable>
      </template>
      <XPagination
        v-if="pagination?.pageCount > 1"
        :class="$style.containerPagination"
        align="center"
        :active-page="pagination?.page"
        :total-pages="pagination?.pageCount"
        :next-text="$t('general.action.next')"
        :prev-text="$t('general.action.previous')"
        data-testid="paginationContainer"
      />
    </XCard>
    <FormFlowListActions
      :modal-showed="modalShowed"
      data-testid="formFlowListActions"
      @close="handleCloseModal"
      @disable="handleDisable"
      @delete="handleDelete"
    />
  </div>
</template>

<style lang="scss" module>
.containerPagination {
  margin-top: var(--space-regular);
}

.tableActions {
  width: 100%;
  margin-bottom: var(--space-medium);
}
</style>

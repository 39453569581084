const users = {
  profile: {
    samlAlert:
      'Tu acceso está gestionado por tu organización. Para cambiar tus datos, contacta al administrador de la cuenta.',
    operatorNotifications: {
      title: 'Correos electrónicos recibidos al enviar documentos',
    },
    signerNotifications: {
      emailsReceivedWhenSignDocuments: 'Correos electrónicos recibidos al firmar documentos',
      emailsReceived: 'Correos electrónicos recibidos',
      deadlineReminder:
        '¿Te gustaría recibir un correo cuando se acerque la fecha límite para firmar?',
      signatureReceipt:
        '¿Te gustaría recibir el comprobante de firma por correo, si hay otras personas que deben firmar después de ti? (No es el documento final)',
      signedDocument: '¿Te gustaría recibir el documento firmado y finalizado también por correo?',
      pausedDocument: '¿Te gustaría recibir un correo cuando el documento sea pausado?',
      canceledDocument: '¿Te gustaría recibir un correo cuando el documento sea cancelado?',
      yes: 'Sí, quiero recibir',
      no: 'No',
      onlyManualReminder: 'No, solo recordatorios manuales enviados por el remitente.',
      onlyStoreOnSignerArea: 'No, almacenar el documento solo en mi área de firmas.',
    },
  },
};

export default users;

import { useStore } from '@/store';
import { getViewProps } from '@base/utils/getViewDataset';

const store = useStore();
const isToggleEnabled = store.getters['featureFlags/isToggleEnabled'];

const routes = [
  // SIGNERS_CONTACT_IMPROVEMENTS
  // TODO: Remove /operator route entry
  {
    path: '/operator',
    component: () => {
      if (isToggleEnabled('signersContactImprovements')) {
        return import('@users/views/UserProfileView.vue');
      }

      return import('@operators/views/ProfileSettingsView.vue');
    },
    meta: {
      layout: 'BaseDefaultLayout',
      refresh: () => true,
    },
    props: getViewProps,
  },
  {
    view: 'MultiFactorSettingsContainer',
    component: () => import('@operators/containers/MultiFactorSettingsContainer.vue'),
  },
  {
    view: 'EmailValidationView',
    path: '/operator/confirmation_email',
    name: 'operatorEmailConfirmation',
    component: () => import('@operators/views/EmailValidation/EmailValidationView.vue'),
  },
  {
    view: 'BlockedRegionView',
    path: '/operator/blocked_region',
    name: 'operatorBlockedRegion',
    component: () => import('@operators/views/BlockedRegion/BlockedRegionView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      refresh: () => true,
    },
  },
];

export default routes;

import { axios } from '@/plugins';

const fetchPendingAnswers = async ({ commit }, { url }) => {
  const { data } = await axios.get(url);

  commit('SET_PENDING_ANSWERS', data.flowProcesses);
  commit('SET_PAGINATION', data.pagination);
  commit('SET_LINKS', data.links);
};

const fetchFlowsFilter = async ({ commit, getters }) => {
  const {
    data: {
      filters: { flows },
    },
  } = await axios.get(getters.getLinks.filterForms);

  commit('SET_FLOWS_FILTER', flows);
};

// TOGGLE_BULK_ACTIONS_ON_PENDING_ANSWERS_ENABLED
// TODO: Remove fetchActionAnswer
const fetchActionAnswer = async (_, link) => {
  await axios.patch(link);
};

const disapproveInBatch = async ({ getters }, payload) => {
  const { ids } = payload;
  const url = getters.getLinks.disapprove;

  await axios.patch(url, { ids });
};

const sendReminderInBatch = async ({ getters }, payload) => {
  const { ids } = payload;
  const url = getters.getLinks.reminder;

  await axios.post(url, { ids });
};

const fetchAnswers = async ({ getters, commit }) => {
  const { data } = await axios.get(getters.getSelectedProcess.links.answers);

  commit('SET_SELECTED_PROCESS_ANSWERS', data);
};

export default {
  fetchPendingAnswers,
  fetchFlowsFilter,
  fetchActionAnswer,
  fetchAnswers,
  disapproveInBatch,
  sendReminderInBatch,
};

<script setup>
import { useI18n } from '@/locales';
import { useRole } from '@base/hooks';
import { FORM_FLOW_ACTIONS_CONSTANTS } from '@flow/constants';
import { camelCase } from 'lodash';
import { computed } from 'vue';

const props = defineProps({
  flow: {
    type: Object,
    default: () => {},
  },
  currentUser: {
    type: Object,
    default: () => {},
  },
});
const emit = defineEmits(['click-option']);

const { t } = useI18n();
const { isAdmin: userIsAdmin } = useRole(props.currentUser);

const handleAction = (option) => {
  emit('click-option', {
    option,
    flow: props.flow,
  });
};

const optionsDropdown = computed(() => {
  const showPrivacyOption = userIsAdmin.value;
  const flowIsEnabled = props.flow.enabled;

  const options = [
    {
      text: t('general.action.edit'),
      icon: 'clone',
      action: () => handleAction(FORM_FLOW_ACTIONS_CONSTANTS.edit),
      dataTestId: 'formFlowEditOption',
    },
    flowIsEnabled && {
      text: t('flowModule.list.actions.copyFormLink'),
      icon: 'link',
      action: () => handleAction(FORM_FLOW_ACTIONS_CONSTANTS.copyFormLink),
      dataTestId: 'formFlowCopyFormLinkOption',
    },
    flowIsEnabled && {
      text: t('general.action.disable'),
      icon: 'minus-octagon',
      action: () => handleAction(FORM_FLOW_ACTIONS_CONSTANTS.disable),
      dataTestId: 'formFlowDisableOption',
    },
    {
      text: t('general.action.duplicate'),
      icon: 'copy',
      action: () => handleAction(FORM_FLOW_ACTIONS_CONSTANTS.duplicate),
      dataTestId: 'formFlowDuplicateOption',
    },
    showPrivacyOption && {
      text: t('flowModule.list.actions.privacy'),
      icon: 'lock',
      action: () => handleAction(FORM_FLOW_ACTIONS_CONSTANTS.privacy),
      dataTestId: 'formFlowPrivacyOption',
    },
    {
      text: t('flowModule.list.actions.info'),
      icon: 'file-search',
      action: () => handleAction(FORM_FLOW_ACTIONS_CONSTANTS.info),
      dataTestId: 'formFlowInfoOption',
    },
    {
      text: t('general.action.delete'),
      icon: 'trash',
      action: () => handleAction(FORM_FLOW_ACTIONS_CONSTANTS.delete),
      dataTestId: 'formFlowDeleteOption',
    },
  ];

  return options.filter(Boolean);
});

const getDropdownTestId = computed(() => {
  return `${camelCase(`${props.flow.name}Dropdown`)}`;
});
</script>

<template>
  <XDropdownMenu
    :options="optionsDropdown"
    placement="bottom-end"
  >
    <template #activator="{ on: openDropdown }">
      <XButton
        model="icon"
        design="link"
        size="sm"
        :data-testid="getDropdownTestId"
        @click="openDropdown"
      >
        <XIcon icon="ellipsis-v" />
      </XButton>
    </template>
  </XDropdownMenu>
</template>

<style lang="scss" module>
.container {
  background-color: white;
}
</style>

import axios from '@/plugins/axios';

export default {
  async fetchUser({ getters, commit }) {
    try {
      const url = getters.getLinks.self;
      const { data: responseData } = await axios.get(url);
      commit('SET_USER', responseData.data.user);
      commit('UPDATE_LINKS', responseData.links);

      return [null, { user: responseData.data.user, links: responseData.links }];
    } catch (err) {
      return [err, null];
    }
  },

  async updateSignerNotifications({ getters, commit }, payload) {
    try {
      const url = getters.getLinks.signerNotifications;

      const { data: responseData } = await axios.patch(url, { user: payload });
      commit('UPDATE_USER', responseData.data.user);

      return [null, responseData.data.user];
    } catch (err) {
      return [err, null];
    }
  },
};

<script setup>
import { useRoute, useRouter } from '@base/hooks';

const router = useRouter();
const route = useRoute();

const handleResetFilters = () => {
  router.push({ path: route.path });
};
</script>

<template>
  <div :class="$style.emptyFlowList">
    <XIcon
      icon="search"
      size="2x"
    />
    <div>
      <div :class="$style.title">
        {{ $t('flowModule.emptySearch.title') }}
      </div>
      <div :class="$style.description">
        {{ $t('flowModule.emptySearch.description') }}
      </div>
    </div>
    <XButton
      data-testid="resetFiltersButton"
      size="sm"
      design="outlined"
      @click="handleResetFilters"
    >
      {{ $t('flowModule.emptySearch.button') }}
    </XButton>
  </div>
</template>

<style lang="scss" module>
.emptyFlowList {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 430px;
  margin: 0 auto;
  gap: var(--space-regular);

  .title {
    font-family: var(--font-family-title);
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
    text-align: center;
    color: var(--color-neutral-1000);
  }

  .description {
    font-size: var(--font-size-regular);
    text-align: center;
    color: var(--color-neutral-800);
  }
}
</style>

export default {
  SET_LINKS: (state, payload) => {
    Object.assign(state.links, { ...payload });
  },
  SET_FOLDERS: (state, payload) => {
    state.folders = payload;
  },
  SET_PAGINATION: (state, payload) => {
    state.pagination = payload;
  },
  SET_TOTAL_ITEMS: (state, payload) => {
    state.totalItems = payload;
  },
};

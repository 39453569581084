const operators = {
  settings: {
    accountsLink: 'Voltar para escolha de contas',
    title: 'Configurações do perfil:',
    titleNew: 'Configurações do perfil',
    information: {
      title: 'Informações',
      action: 'Atualizar informações',
      success: 'Informações atualizadas com sucesso',
      titleNew: 'Dados de acesso',
      name: 'Nome',
      email: 'Email',
      phone: 'Celular',
      cpf: 'CPF',
      birthday: 'Data de nascimento',
      save: 'Salvar',
      emailInfoLink: 'Como alterar o e-mail de cadastro',
      incomplete: 'Complete seu cadastro para aproveitar melhor nossos recursos',
      samlAlert:
        'Seu acesso é gerenciado pela sua organização. Para alterar seus dados, entre em contato com o administrador da conta.',
    },
    password: {
      title: 'Senha',
      change: 'Alterar senha',
      new: 'Nova senha',
      action: 'Atualizar senha',
      success: 'Senha alterada com successo!',
      fail: 'Senha atual inválida',
      current: 'Senha atual',
      newConfirmation: 'Nova senha',
      addPassword: 'Adicionar senha',
      noPassword: 'Nenhuma senha salva atualmente',
      confirmation: 'Confirme sua senha',
      save: 'Salvar senha',
      cancelAction: 'Cancelar',
      linkAccount: 'Conectar',
      linkedAccount: 'Conectado',
      ssoTitle: 'Contas conectadas',
      ssoAccounts:
        'Conecte sua conta Google ou Microsoft à Clicksign. Lembre-se: o e-mail deve ser o mesmo que você já usa para fazer login.',
      googleAccount: 'Conta Google',
      microsoftAccount: 'Conta Microsoft',
      forgotPassword: 'Esqueci minha senha',
      accessTooltip: 'Saiba mais sobre contas vinculadas',
      forgotPasswordToast: 'Enviamos um e-mail com as instruções para criar uma nova senha',
    },
    multiFactorAuth: {
      title: 'Verificação em duas etapas',
      link: 'Como habilitar verificação em duas etapas',
      enableButton: 'Habilitar',
      resetButton: 'Redefinir',
      disableButton: 'Desabilitar ',
      incomplete:
        'Recuperação de acesso via SMS não disponível. Cadastre seu celular em dados de acesso.',
      status: {
        disabled: 'Desabilitado',
        enabled: 'Habilitado',
      },
      settings: {
        title: 'Verificação em duas etapas',
        authAppsLink: 'Veja os aplicativos que você pode usar',
        firstStep: '1. Instale um aplicativo de autenticação',
        secondStep: '2. Escaneie o QR Code ou copie o código abaixo:',
        thirdStep: '3. Insira o código de seis dígitos gerado no aplicativo',
        button: 'Habilitar',
        success: {
          title: 'Verificação em duas etapas habilitada',
          successText: 'Agora sua conta está ainda mais segura',
          codeRecovery: {
            title: 'Códigos de recuperação',
            text: 'Os códigos abaixo permitem que você acesse a Clicksign caso tenha problemas com o aplicativo de autenticação. Guarde-os em local seguro.',
            warn: 'Estes códigos não serão exibidos novamente após esta etapa.',
            download: 'Baixar códigos de recuperação',
            backupFileName: 'Códigos de Recuperação - Clicksign{format}',
          },
          phoneConfirm: {
            title: 'Confirme seu número de celular',
            text: 'Confirmando você possibilita recuperação de acesso à conta via SMS',
            phone: 'Celular',
            error: 'Erro ao atualizar celular. Tente novamente mais tarde',
          },
          button: 'Finalizar',
        },
        errors: {
          default: 'Erro ao realizar autenticação. Tente novamente mais tarde',
        },
      },
      optinModal: {
        title: 'Habilitar verificação em duas etapas',
        confirm: 'Confirme sua identidade',
        securityReasons:
          'Por segurança, precisamos confirmar que o pedido de habilitação foi feito por você.',
        clickToSend: 'Clique em ”Enviar e-mail”',
        dispatchEmail: '{0} para disparar o pedido de confirmação de identidade para {1}.',
        notReceiveEmail:
          'Caso o e-mail não esteja na sua caixa de entrada, confira a pasta de spam.',
        error: 'Erro ao enviar e-mail de confirmação. Atualize a página e tente novamente.',
        success: 'E-mail enviado com sucesso',
        resend: 'Enviar e-mail',
        tryResend: 'Reenviar em {time} segundos',
        close: 'Fechar',
      },
    },
    resetModal: {
      header: 'Redefinir verificação em duas etapas?',
      text: 'Todas as informações sobre o dispositivo configurado anteriormente serão substituídas. Esta ação não poderá ser desfeita.',
      confirmCheckbox: 'Selecione para confirmar a redefinição',
      confirmButton: 'Redefinir',
    },
    disableModal: {
      header: 'Desabilitar verificação em duas etapas?',
      text: 'As informações sobre o dispositivo configurado para verificação serão excluídas.',
      confirmCheckbox: 'Selecione para confirmar desabilitação',
      confirmButton: 'Desabilitar',
      success: 'Verificação em duas etapas desabilitada com sucesso',
    },
    notification: {
      finishedDocumentLabel: 'Quer receber email quando o documento for finalizado?',
      refusedDocumentLabel: 'Quer receber email quando um signatário recusar uma assinatura?',
      documentDeadlineLabel: 'Quer receber email 3 dias antes do documento ser finalizado?',
      emailErrorLabel: 'Quer receber aviso por e-mail quando houver um erro no envio do envelope?',
      options: {
        own: 'Sim. Apenas para {type} criados por mim.',
        all: 'Sim. Para todos os {type}.',
        none: 'Não quero receber.',
      },
      success: 'Configurações de notificações alteradas com sucesso',
      section: 'Lembretes',
      action: 'Salvar lembretes',
      envelopes: 'envelopes',
      documents: 'documentos',
    },
    remoteUpdate: {
      success: 'Alteração realizada com sucesso',
    },
  },
  emailConfirmed: {
    subtitle: 'E-mail confirmado. Vamos enviar o primeiro documento?',
    dismissButton: 'Agora não',
    confirmButton: 'Sim, vamos lá!',
  },
  emailConfirmation: {
    title: 'Agora é só confirmar seu e-mail',
    description: 'Clique no link que enviamos para {email}',
    resendButton: 'Não recebi',
    skipButton: 'Confirmar mais tarde',
  },
  blockedRegion: {
    title: 'Esta página ainda não está disponível no seu país.',
    subtitle:
      'Estamos empenhados em disponibilizar esta funcionalidade em breve. Ou volte ao início para continuar explorando as funcionalidades disponíveis.',
    survey: 'pesquisa rápida',
    return: 'Voltar ao início',
  },
};

export default operators;

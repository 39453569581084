const users = {
  profile: {
    samlAlert:
      'Seu acesso é gerenciado pela sua organização. Para alterar seus dados, entre em contato com o administrador da conta.',
    operatorNotifications: {
      title: 'E-mails recebidos ao enviar documentos',
    },
    signerNotifications: {
      emailsReceivedWhenSignDocuments: 'E-mails recebidos ao assinar documentos',
      emailsReceived: 'E-mails recebidos',
      deadlineReminder: 'Quer receber e-mail quando a data limite de assinatura estiver próxima?',
      signatureReceipt:
        'Quer receber o comprovante de assinatura por e-mail, caso existam outras pessoas para assinar depois de você? (Não é o documento final)',
      signedDocument: 'Quer receber o documento assinado e finalizado também por e-mail?',
      pausedDocument: 'Quer receber e-mail quando o documento for pausado?',
      canceledDocument: 'Quer receber e-mail quando o documento for cancelado?',
      yes: 'Sim, quero receber',
      no: 'Não',
      onlyManualReminder: 'Não, apenas lembretes manuais enviados pelo remetente.',
      onlyStoreOnSignerArea: 'Não, armazenar o documento apenas na minha área de assinaturas.',
    },
  },
};

export default users;

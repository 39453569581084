<script setup>
import { useFormFlowPendingProcesses } from '@flow/composables';

defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['disable', 'close']);
const {
  hasPendingProcesses,
  hasWaitingSecondFormProcesses,
  handleGoToProcesses,
  handleGoToPendingAnswers,
  verifyPendingProcesses,
  modalInvalidType,
  hasProcessesOrAnswers,
  loading,
} = useFormFlowPendingProcesses();

const handleSubmit = () => {
  if (hasPendingProcesses.value) {
    handleGoToProcesses();
  } else if (hasWaitingSecondFormProcesses.value) {
    handleGoToPendingAnswers();
  } else {
    emit('disable');
  }
};
</script>

<template>
  <XModal
    :is-open="showModal"
    :loading="loading"
    data-testid="formFlowDisableModalContainer"
    @close="$emit('close')"
    @opened="verifyPendingProcesses"
  >
    <template #header>
      {{
        hasProcessesOrAnswers
          ? $t(`flowModule.modalDisable.${modalInvalidType}.header`)
          : $t('flowModule.modalDisable.header')
      }}
    </template>
    <template #default>
      <p :class="$style.text">
        {{
          hasProcessesOrAnswers
            ? $t(`flowModule.modalDisable.${modalInvalidType}.title`)
            : $t('flowModule.modalDisable.title')
        }}
      </p>
    </template>
    <template #actions>
      <div :class="$style.actions">
        <XButton
          design="outlined"
          data-testid="modalCloseButton"
          @click="$emit('close')"
        >
          {{ $t('general.action.cancel') }}
        </XButton>
        <XButton
          data-testid="modalDisableButtonSubmit"
          @click="handleSubmit"
        >
          {{
            hasProcessesOrAnswers
              ? $t(`flowModule.modalDisable.${modalInvalidType}.action`)
              : $t('general.action.disable')
          }}
        </XButton>
      </div>
    </template>
  </XModal>
</template>

<style lang="scss" module>
.text {
  margin-bottom: 0;
}

.actions {
  display: flex;
  gap: var(--space-small-xx);
}
</style>

import { getViewProps } from '@base/utils/getViewDataset';
import { FormFlowComplianceView, FormFlowShowView, FormFlowListView } from '@flow/views/form-flow';
import { PendingAnswersView } from '@flow/views/pending-answers';
import { FormFlowTableList, FormFlowFoldersTableList } from '@flow/components/form-flow';
import { useStore } from '@/store';

const store = useStore();

// TOGGLE_FLOW_SIMPLIFIED_JOURNEY_ENABLED
// TODO: remove simplifiedJourney
const simplifiedJourney = () => store.state.info.toggles.toggleFlowSimplifiedJourneyEnabled;

const routes = [
  {
    path: '/accounts/:accountId/form_builders',
    name: 'formBuilderIndex',
    meta: {
      layout: 'BaseNavigationLayout',
    },
    component: () => import('@flow/views/form-builder/FormBuilderListView.vue'),
  },
  {
    path: '/accounts/:accountId/form_builders/new',
    component: () => import('@flow/views/form-builder/FormBuilderSetupView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        path: 'formBuilderIndex',
      },
      refresh: () => true,
    },
    props: getViewProps,
  },
  {
    path: '/accounts/:accountId/form_builders/:formId/edit',
    component: () => import('@flow/views/form-builder/FormBuilderSetupView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        path: 'formBuilderIndex',
      },
      refresh: () => true,
    },
    props: getViewProps,
  },
  {
    name: 'formBuilderShow',
    path: '/accounts/:accountId/form_builders/:formId',
    component: () => import('@flow/views/form-builder/FormBuilderFormView.vue'),
    meta: {
      preview: true,
      layout: 'BaseCleanLayout',
      refresh: () => true,
      legacyProps: true,
    },
  },
  {
    path: '/fluxia/:flowKey',
    component: () => import('@flow/views/form-builder/FormBuilderFormView.vue'),
    meta: {
      layout: 'BaseCleanLayout',
      refresh: () => true,
      legacyProps: true,
    },
  },
  {
    path: '/fluxia/:flow_key/process/:key',
    component: () => import('@flow/views/form-builder/FormBuilderFormView.vue'),
    meta: {
      layout: 'BaseCleanLayout',
      refresh: () => true,
      legacyProps: true,
    },
  },
  {
    path: '/accounts/:accountId/flow/processes',
    name: 'flowProcesses',
    meta: {
      layout: 'BaseNavigationLayout',
    },
    component: FormFlowComplianceView,
  },
  {
    name: 'formFlowIndex',
    path: '/accounts/:accountId/flows',
    meta: {
      layout: 'BaseNavigationLayout',
    },
    component: FormFlowListView,
    children: [
      {
        name: 'formFlowListFlows',
        path: '',
        component: FormFlowTableList,
        meta: {
          layout: 'BaseNavigationLayout',
        },
      },
      {
        name: 'formFlowListFolders',
        path: 'folders',
        component: FormFlowFoldersTableList,
      },
    ],
  },
  // TOGGLE_FLOW_SIMPLIFIED_JOURNEY_ENABLED
  // TODO: remove simplifiedJourney
  {
    name: 'formFlowSetup',
    path: '/accounts/:accountId/flows/:flowId/edit',
    component: () =>
      simplifiedJourney()
        ? import('@flow/views/form-flow/FormFlowSetupView.vue')
        : import('@flow/views/form-flow/FormFlowSetupViewOld.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        path: 'formFlowListFlows',
      },
    },
  },
  {
    path: '/accounts/:accountId/spreadsheet_flows/:spreadsheetId/edit',
    name: 'spreadsheetFlowSetup',
    component: () => import('@/modules/flow/views/spreadsheet-flow/SpreadsheetFlowSetupView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        path: 'spreadsheetFlows',
      },
      refresh: () => true,
    },
    props: getViewProps,
  },
  {
    path: '/accounts/:accountId/spreadsheet_flows',
    name: 'spreadsheetFlows',
    meta: {
      layout: 'BaseNavigationLayout',
    },
    component: () => import('@flow/views/spreadsheet-flow/SpreadsheetFlowView.vue'),
  },
  {
    path: '/accounts/:accountId/templates',
    name: 'templates',
    meta: {
      layout: 'BaseNavigationLayout',
    },
    component: () => import('@flow/views/template/TemplateListView.vue'),
  },
  {
    view: 'TemplateMergeView',
    component: () => import('@flow/views/template/TemplateMergeView.vue'),
  },
  {
    path: '/accounts/:accountId/templates/new',
    name: 'templateSetup',
    meta: {
      layout: 'BaseNavigationLayout',
    },
    props: getViewProps,
    component: () => import('@flow/views/template/TemplateSetupView.vue'),
  },
  {
    path: '/accounts/:accountId/chat/flows/new',
    name: 'automationChat',
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
      },
    },
    props: getViewProps,
    component: () => import('@flow/views/automation-chat/AutomationChatView.vue'),
  },
  {
    path: '/accounts/:accountId/templates/:templateId/edit',
    name: 'templateSetupEdit',
    meta: {
      layout: 'BaseNavigationLayout',
    },
    props: getViewProps,
    component: () => import('@flow/views/template/TemplateSetupView.vue'),
  },
  {
    path: '/accounts/:accountId/flows/:flowId',
    name: 'formFlowShow',
    meta: {
      layout: 'BaseNavigationLayout',
    },
    component: FormFlowShowView,
  },
  {
    path: '/accounts/:accountId/flow/processes/waiting_second_form',
    name: 'pendingAnswers',
    meta: {
      layout: 'BaseNavigationLayout',
    },
    component: PendingAnswersView,
  },
];

export default routes;

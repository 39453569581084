import { axios } from '@/plugins';

const fetchIncentives = async ({ rootGetters, commit }) => {
  try {
    const requestUrl = rootGetters['info/getLinks']?.incentives;
    if (!requestUrl) return {};

    const { data } = await axios.get(requestUrl);

    commit('SET_INCENTIVES', data);
    commit('SET_LINKS', data.links);
    return data;
  } catch (err) {
    return null;
  }
};

const changeRecurrence = async ({ rootGetters }, payload) => {
  const endpoint = rootGetters['incentives/getLinks']?.displayed;
  await axios.post(endpoint, payload);
};

export default {
  fetchIncentives,
  changeRecurrence,
};

import VueI18n from 'vue-i18n';
import Vue from 'vue';

import { useI18n as useI18nExcalibur } from '@clicksign/excalibur-vue2/composables';
import moment from 'moment';
import enUs from './en_US';
import ptBr from './pt_BR';
import esMx from './es_MX';
import vivo from './vivo';

Vue.use(VueI18n);

const messages = {
  'en-US': enUs.messages,
  'pt-BR': ptBr.messages,
  'es-MX': esMx.messages,
  'vivo-pt-BR': vivo.messages,
};

const dateTimeFormats = {
  'en-US': enUs.dateTimeFormats,
  'pt-BR': ptBr.dateTimeFormats,
  'es-MX': esMx.messages,
};

const defaultLocale = 'pt-BR';

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: {
    enUs: ['en-US'],
    en: ['en-US'],
    esMx: ['es-MX'],
    default: [defaultLocale],
  },
  messages,
  dateTimeFormats,
});

export default i18n;

export function setLocale(locale) {
  if (!locale) return;
  i18n.locale = locale;
  const { setLocale: setExcaliburLocale } = useI18nExcalibur();
  setExcaliburLocale(locale);
  moment.locale(locale);
}

export const useI18n = () => ({
  t: (key, values, locale) => i18n.t(key, locale, values),
  tc: (key, choice, values, locale) => i18n.tc(key, choice, locale, values),
  te: (key, locale) => i18n.te(key, locale),
  d: (value, key, locale) => i18n.d(value, key, locale),
  n: (value, format, locale) => i18n.n(value, format, locale),
  setLanguage: (locale) => {
    i18n.locale = locale;
  },
  setLocale,
});

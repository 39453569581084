import { getViewProps } from '@base/utils/getViewDataset';
import store from '@/store';
// TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
// TODO: Remove usTrialExperiment import and constant
import { useTrialExperiment } from '@base/composables';

const { isTrialExperiment } = useTrialExperiment();

// TOGGLE_MIDAS_CLIENT_INTEGRATION_ENABLED
// TODO: remove midasEnabled
const midasEnabled = () => store.state.info.toggles.toggleMidasClientIntegrationEnabled;

const routes = [
  {
    path: '/accounts/:accountId/reports',
    name: 'reports',
    component: () =>
      // TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
      // TODO: Remove ternary and keep ReportListView import
      isTrialExperiment.value
        ? import('@report/views/ReportListSimplifiedView.vue')
        : import('@report/views/ReportListView.vue'),
    meta: {
      layout: 'BaseNavigationLayout',
    },
    props: getViewProps,
  },
  // TOGGLE_MIDAS_CLIENT_INTEGRATION_ENABLED
  // TODO: keep route and remove import ReportConsumptionViewOld
  {
    path: '/accounts/:accountId/reports/consumption_usages',
    name: 'reportConsumptionUsage',
    component: () =>
      import('@report/views/ReportConsumptionUsageView/ReportConsumptionUsageView.vue'),
    view: 'ReportConsumptionUsageView',
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        path: 'reports',
      },
      refresh: () => !midasEnabled(),
    },
  },
  // TOGGLE_MIDAS_CLIENT_INTEGRATION_ENABLED
  // TODO: keep route and remove import ReportConsumptionViewOld
  {
    path: '/accounts/:accountId/reports/consumption',
    name: 'reportConsumption',
    component: () =>
      midasEnabled()
        ? import('@report/views/ReportConsumptionView/ReportConsumptionView.vue')
        : import('@report/views/ReportConsumptionView/ReportConsumptionViewOld.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        path: 'reports',
      },
      refresh: () => !midasEnabled(),
    },
    props: getViewProps,
  },
  {
    path: '/accounts/:accountId/billing_integration/consumptions',
    name: 'reportBillingConsumption',
    component: () => import('@report/views/ReportConsumptionView/ReportConsumptionView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        path: 'reports',
      },
      refresh: () => false,
    },
  },
  {
    path: '/accounts/:accountId/reports/documents',
    name: 'reportDocuments',
    component: () => import('@report/views/ReportDocumentView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        path: 'reports',
      },
      refresh: () => true,
    },
    props: getViewProps,
  },
  {
    path: '/accounts/:accountId/reports/flows',
    name: 'reportFormFlows',
    component: () => import('@report/views/ReportFormFlowView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        path: 'reports',
      },
      refresh: () => true,
    },
    props: getViewProps,
  },
  {
    path: '/accounts/:accountId/reports/signers',
    name: 'reportSigners',
    component: () => import('@report/views/ReportSignerView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      closeLink: {
        show: true,
        path: 'reports',
      },
      refresh: () => true,
    },
    props: getViewProps,
  },
];

export default routes;

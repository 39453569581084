<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter, useRoute } from '@base/hooks';
import { camelizeKeys } from '@base/utils';
import { useDebounceFn } from '@vueuse/core';
import moment from 'moment';
import { useI18n } from '@/locales';

defineProps({
  totalItems: {
    type: Number,
    default: 0,
  },
});

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const filterValues = ref({
  search: '',
  dateRange: {},
  enabled: '',
});

const MIN_TEXT_LENGTH = 3;
const SEARCH_DEBOUNCE_DELAY = 400;
const STATUS_OPTIONS = [
  { text: t('flowModule.filters.status.all'), value: '' },
  { text: t('flowModule.filters.status.enabled'), value: 'true' },
  { text: t('flowModule.filters.status.disabled'), value: 'false' },
];
const maxDate = moment().format('YYYY-MM-DD');

const filterIsOpen = ref(false);
const dateTimePickerIsOpen = ref(false);

const textSearchParam = computed(() => route.query.name || '');
const enabledParam = computed(() => route.query.enabled || '');
const dateRangeParam = computed(() => ({
  start: route.query.start_date || route.query.startDate,
  end: route.query.end_date || route.query.endDate,
}));
const filterHasNotChanged = computed(
  () =>
    enabledParam.value === filterValues.value.enabled &&
    dateRangeParam.value.start === filterValues.value.dateRange.start &&
    dateRangeParam.value.end === filterValues.value.dateRange.end
);
const formattedDateRange = computed(() => {
  const { start, end } = filterValues.value.dateRange;
  const formattedStartDate = start && moment(start).format('DD/MM/YYYY');
  const formattedEndDate = end ? moment(end).format('DD/MM/YYYY') : '...';
  const hasDateSelected = !!start || !!end;

  return hasDateSelected ? `${formattedStartDate} - ${formattedEndDate}` : '';
});
const totalAppliedFilters = computed(() => {
  let total = 0;

  if (filterValues.value.enabled) {
    total += 1;
  }

  if (filterValues.value.dateRange.start || filterValues.value.dateRange.end) {
    total += 1;
  }

  return total;
});

const filterAllowedQueryParams = (queryParams) => {
  const { name, enabled, startDate, endDate } = camelizeKeys(queryParams);

  return { name, enabled, startDate, endDate };
};

const skipSearch = (textSearch) => {
  const trimmed = textSearch.trim();
  const textSearchHasNotChanged = trimmed === textSearchParam.value.trim();
  const textSearchIsEmpty = trimmed.length === 0;

  return textSearchHasNotChanged || (trimmed.length < MIN_TEXT_LENGTH && !textSearchIsEmpty);
};

const handleSearch = () => {
  if (skipSearch(filterValues.value.search)) return;

  router.replace({
    query: {
      ...filterAllowedQueryParams(route.query),
      name: filterValues.value.search.trim(),
    },
  });
};

const clearFilters = () => {
  filterValues.value.enabled = '';
  filterValues.value.dateRange = {};
};

const applyFilters = () => {
  router.push({
    query: {
      ...filterAllowedQueryParams(route.query),
      enabled: filterValues.value.enabled,
      startDate: filterValues.value.dateRange.start,
      endDate: filterValues.value.dateRange.end,
    },
  });
};

const debouncedSearch = useDebounceFn(handleSearch, SEARCH_DEBOUNCE_DELAY);

onMounted(() => {
  filterValues.value = {
    search: textSearchParam.value,
    dateRange: dateRangeParam.value,
    enabled: enabledParam.value,
  };
});

watch(() => filterValues.value.search, debouncedSearch);
</script>

<template>
  <div
    :class="$style.filterContainer"
    data-testid="formFlowListFilters"
  >
    <XTextInput
      v-model="filterValues.search"
      :class="$style.search"
      :placeholder="$t('flowModule.filters.search')"
      full-width
      autofocus
      grouped
      data-testid="formFlowListFiltersTextSearch"
      @keyup.native.enter="handleSearch"
    >
      <XButton
        model="plain"
        @click="handleSearch"
      >
        <XIcon icon="search" />
      </XButton>
    </XTextInput>

    <XDropdown
      :is-open="filterIsOpen"
      :offset="4"
      placement="bottom-end"
      :close-on-click-content="false"
    >
      <template #activator="{ on }">
        <XButton
          size="sm"
          design="outlined"
          data-testid="formFlowListFiltersOpenFilters"
          @click="on"
        >
          {{
            $tc('flowModule.filters.activator', totalAppliedFilters, { count: totalAppliedFilters })
          }}

          <XIcon
            icon="filter"
            right
          />
        </XButton>
      </template>
      <XCard
        :class="[$style.filters, dateTimePickerIsOpen && $style.minHeight]"
        elevation="high"
      >
        <h5>{{ $t('flowModule.filters.title') }}</h5>

        <XSelect
          v-model="filterValues.enabled"
          :class="$style.selectStatus"
          :items="STATUS_OPTIONS"
          :label="$t('flowModule.filters.statusLabel')"
          full-width
          inner-dropdown
          data-testid="formFlowListFiltersSelectStatus"
        />

        <XDateTimePicker
          v-model="filterValues.dateRange"
          :locale="$i18n.locale"
          :max-date="maxDate"
          format="YYYY-MM-DD"
          no-header
          no-label
          no-shortcuts
          no-value-to-custom-elem
          only-date
          range
          @is-shown="dateTimePickerIsOpen = true"
          @is-hidden="dateTimePickerIsOpen = false"
        >
          <XTextInput
            :value="formattedDateRange"
            :placeholder="$t('flowModule.filters.dateRangePlaceholder')"
            readonly
            :label="$t('flowModule.filters.dateRangeLabel')"
            full-width
            grouped
          >
            <XButton model="plain">
              <XIcon icon="calendar-alt" />
            </XButton>
          </XTextInput>
        </XDateTimePicker>

        <div :class="$style.actions">
          <XButton
            design="outlined"
            size="sm"
            data-testid="formFlowListFiltersClearFilters"
            @click="clearFilters"
          >
            {{ $t('flowModule.filters.clear') }}
          </XButton>

          <XButton
            size="sm"
            :disabled="filterHasNotChanged"
            data-testid="formFlowListFiltersApplyFilters"
            @click="applyFilters"
          >
            {{ $t('flowModule.filters.apply') }}
          </XButton>
        </div>
      </XCard>
    </XDropdown>

    <span :class="$style.spanItems">
      {{ $tc('flowModule.list.flowLength', totalItems, { count: totalItems }) }}
    </span>
  </div>
</template>

<style lang="scss" module>
.filterContainer {
  display: flex;
  align-items: center;
  gap: var(--space-small-x);

  .search {
    max-width: 390px;
  }

  .spanItems {
    color: var(--color-neutral-700);
  }
}

.filters {
  width: 80vw;
  max-width: 430px;

  .selectStatus {
    margin-bottom: var(--space-regular);
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: var(--space-regular);
    column-gap: var(--space-small-xx);
  }

  @media (max-width: 415px) {
    &.minHeight {
      min-height: 385px;
    }
  }
}
</style>

import links from '@/locales/pt_BR/links';

import general from './general';
import document from './document';
import download from './download';
import signature from './signature';
import steps from './steps';
import validation from './validation';
import dateTimeFormats from './date-time-formats';
import rubric from './rubric';
import location from './location';

const messages = {
  general,
  document,
  download,
  signature,
  validation,
  steps,
  rubric,
  location,
  links,
};

export default {
  messages,
  dateTimeFormats,
};

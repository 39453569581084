import general from './general';
import document from './document';
import download from './download';
import signature from './signature';
import validation from './validation';
import dateTimeFormats from './date-time-formats';
import steps from './steps';
import rubric from './rubric';
import location from './location';
import links from './links';

const messages = {
  general,
  document,
  signature,
  validation,
  download,
  rubric,
  steps,
  location,
  links,
};

export default {
  messages,
  dateTimeFormats,
};

const signer = {
  group: 'Grupo {0}',
  list: {
    title: 'Lista de signatários',
    signaturesDone: '{totalSigned}/{totalSignatures} Assinaturas efetuadas',
    tracking: 'E-mails enviados',
    signatureRefused: 'Assinatura recusada',
    communicateBy: 'Envio via',
    seeMore: 'Ver menos|Ver mais',
    loading: 'Carregando informações...',
    email: 'E-mail',
    whatsapp: 'WhatsApp',
    sms: 'SMS',
    phoneNumber: 'Número do celular',
    cpf: 'CPF',
    birthday: 'Data de nascimento',
    nameVerification: {
      title: 'Verificação do nome',
      conferred: 'Nome confere com a base de dados da Receita Federal.',
      cpfNotFound: 'CPF informado não existe na base de dados da Receita Federal.',
      cpfInvalid: 'CPF informado não é válido.',
      divergentName:
        'Nome preenchido diverge do nome cadastrado na base de dados da Receita Federal. ({name})',
      divergentBirthday:
        'Data de nascimento preenchida diverge da data cadastrada na base de dados da Receita Federal. ({birthday})',
      receitaProxyFail: 'Ocorreu um erro durante a consulta de dados na Receita Federal.',
      pending: 'Verificação em andamento.',
      cpfEmpty: 'Verificação não realizada.',
      cpfRegularizationPending:
        'CPF informado com regularização pendente na base de dados da Receita Federal.',
      cpfOwnerDeceased: 'Proprietário do CPF falecido segundo a base de dados da Receita Federal.',
      cpfSuspended: 'CPF informado está suspenso segundo a base de dados da Receita Federal.',
      cpfCanceled: 'CPF informado está cancelado segundo a base de dados da Receita Federal.',
      cpfNull:
        'CPF anulado após identificação de fraude cadastral segundo a base de dados da Receita Federal.',
      signerValidatorFail: 'Ocorreu um erro durante a consulta de dados na Receita Federal.',
    },
    signedAt: 'Data da assinatura',
    authAsPix: 'Autenticação por pix',
    authAsList: 'Autenticar usando',
    pixVerification: '{0} (E2EId / Banco Central)',
    icpBrasil: {
      signedWith:
        '{subjectName} {certificateType} assinou com certificado digital ICP-Brasil válido até {validityEnd}.',
      details: 'CPF: {cpf}, Número Serial do Cerficado ICPBR: {serialNumber}',
    },
    refusal: {
      enabledLabel: 'Recusa de assinatura',
      isEnabled: 'habilitada',
      signAs: 'Assinatura recusada',
      reasonTooltip: 'Motivo da recusa',
      infoTooltip: 'Exclua e adicione novamente o signatário para que ele tente assinar outra vez',
    },
    remind: {
      refused: 'Este signatário recusou a assinatura',
      blocked: 'O documento está pausado',
      outsideActiveGroup: 'Aguardando a assinatura de outro signatário',
    },
    actions: {
      deleteConfirm: 'Clique para confirmar',
    },
  },
  toolbar: {
    newSigner: 'Signatário novo',
    newContact: 'Signatário da agenda',
    sequence: 'Ordenar assinaturas',
  },
  evidence: {
    modal: {
      filename: 'Nome do arquivo',
      selfie: 'Selfie com documento',
      handwritten: 'Assinatura manuscrita',
      officialDocumentFront: 'Frente do documento oficial',
      officialDocumentBack: 'Verso do documento oficial',
      liveness: 'Selfie dinâmica',
      facialBiometrics: 'Biometria facial',
    },
  },
  sequence: {
    title: 'Configurar ordem das assinaturas',
    checkbox: {
      label: 'Ativar ordenação das assinaturas.',
      tooltip:
        'Uma vez ativada a ordenação das assinaturas, não é possível desativa-la no documento. Se você deseja todos os signatários assinando simultaneamente, coloque-os no mesmo grupo.',
    },
    dragAndDropInfo: 'Arraste e solte para mudar de grupo',
    banner: {
      activeSequence:
        'Ative a ordenação das assinaturas para definir quem deverá assinar primeiro, em segundo e assim por diante.',
      emptyGroups: 'Adicione signatários para ordenar as assinaturas.',
    },
    dragAndDropPlaceholder: 'ARRASTE AQUI PARA ADICIONAR UM NOVO GRUPO',
    successSubmit: 'Ordenação de assinaturas atualizada com sucesso!',
  },
  auths: {
    api: 'Api',
    sms: 'Token via SMS',
    whatsapp: 'Token via WhatsApp',
    pix: 'Pix',
    email: 'Token via E-mail',
    icpBrasil: 'Certificado digital',
    officialDocument: 'Documento oficial',
    selfie: 'Selfie com documento',
    liveness: 'Selfie dinâmica',
    handwritten: 'Assinatura manuscrita',
    facialBiometrics: 'Biometria facial',
    addressProof: 'Comprovante de endereço',
    biometric: 'Biometria facial - CAF e SERPRO',
  },
  roles: {
    preffix: {
      as: 'Como',
      to: 'Para',
      sign: 'Assinar',
      signed: 'Assinou',
      wouldSign: 'Assinará',
    },
    didNotSign: 'Não assinou',
    sign: 'Assinar',
    signed: 'Assinou',
    wouldSign: 'Assinará',
    witness: 'Testemunha',
    party: 'Parte',
    intervening: 'Interveniente',
    receipt: 'Acusar recebimento',
    approve: 'Aprovar',
    endorser: 'Endossante',
    endorsee: 'Endossatário',
    administrator: 'Administrador',
    guarantor: 'Avalista',
    transferor: 'Cedente',
    transferee: 'Cessionário',
    contractee: 'Contratada',
    contractor: 'Contratante',
    jointDebtor: 'Devedor solidário',
    issuer: 'Emitente',
    manager: 'Gestor',
    buyer: 'Parte compradora',
    seller: 'Parte vendedora',
    attorney: 'Procurador',
    legalRepresentative: 'Representante legal',
    coResponsible: 'Responsável solidário',
    validator: 'Validador',
    surety: 'Fiador',
    lessor: 'Locador',
    lessee: 'Locatário',
    ratify: 'Homologar',
    employer: 'Empregador',
    employee: 'Empregado',
    consenting: 'Anuente',
    accountant: 'Contador',
    debtor: 'Devedor',
    franchisee: 'Franqueado',
    creditor: 'Credor',
    franchisor: 'Franqueador',
    insured: 'Segurado',
    grantor: 'Outorgante',
    president: 'Presidente',
    partner: 'Sócio',
    comforter: 'Comodante',
    accountHolder: 'Correntista',
    distracted: 'Distratada',
    distracting: 'Distratante',
    lender: 'Mutuante',
    associate: 'Associado',
    broker: 'Corretor',
    bailee: 'Fiel depositário',
    collateralProvider: 'Caucionante',
    pledged: 'Consignado',
    consignee: 'Consignatário',
    lawyer: 'Advogado',
    grantee: 'Outorgado',
    borrower: 'Comodatário',
    guarantorSpouse: 'Cônjuge do fiador',
    legalGuardian: 'Responsável legal',
    director: 'Diretor(a)',
    realEstateBroker: 'Corretor de imóveis',
    insuranceBroker: 'Corretor de seguros',
    licensor: 'Licenciante',
    licensed: 'Licenciada',
    serviceProvider: 'Prestador(a) de serviços',
    secured: 'Afiançado',
    consented: 'Anuído',
    interveningGuarantor: 'Interveniente Garantidor',
    consentingIntervenor: 'Interveniente Anuente',
    surveyor: 'Vistoriador',
    buildingManager: 'Síndico(a)',
    intermediary: 'Intermediário(a)',
    condominiumMember: 'Condômino',
    owner: 'Proprietário(a)',
    resident: 'Morador(a)',
    treasurer: 'Tesoureiro(a)',
    secretary: 'Secretário(a)',
  },
  signedAs: {
    sign: 'Assinou',
    approve: 'Assinou para aprovar',
    party: 'Assinou como parte',
    witness: 'Assinou como testemunha',
    intervening: 'Assinou como interveniente',
    receipt: 'Assinou para acusar recebimento',
    endorser: 'Assinou como endossante',
    endorsee: 'Assinou como endossatário',
    administrator: 'Assinou como administrador',
    guarantor: 'Assinou como avalista',
    transferor: 'Assinou como cedente',
    transferee: 'Assinou como cessionário',
    contractee: 'Assinou como contratada',
    contractor: 'Assinou como contratante',
    jointDebtor: 'Assinou como devedor solidário',
    issuer: 'Assinou como emitente',
    manager: 'Assinou como gestor',
    buyer: 'Assinou como parte compradora',
    seller: 'Assinou como parte vendedora',
    attorney: 'Assinou como procurador',
    legalRepresentative: 'Assinou como representante legal',
    coResponsible: 'Assinou como responsável solidário',
    validator: 'Assinou como validador',
    surety: 'Assinou como fiador',
    lessor: 'Assinou como locador',
    lessee: 'Assinou como locatário',
    ratify: 'Assinou para homologar',
    employer: 'Assinou como empregador',
    employee: 'Assinou como empregado',
    consenting: 'Assinou como anuente',
    accountant: 'Assinou como contador',
    debtor: 'Assinou como devedor',
    franchisee: 'Assinou como franqueado',
    creditor: 'Assinou como credor',
    franchisor: 'Assinou como franqueador',
    insured: 'Assinou como segurado',
    grantor: 'Assinou como outorgante',
    president: 'Assinou como presidente',
    partner: 'Assinou como sócio',
    comforter: 'Assinou como comodante',
    accountHolder: 'Assinou como correntista',
    distracted: 'Assinou como distratada',
    distracting: 'Assinou como distratante',
    lender: 'Assinou como mutuante',
    associate: 'Assinou como associado',
    broker: 'Assinou como corretor',
    bailee: 'Assinou como fiel depositário',
    collateralProvider: 'Assinou como caucionante',
    pledged: 'Assinou como consignado',
    consignee: 'Assinou como consignatário',
    lawyer: 'Assinou como advogado',
    grantee: 'Assinou como outorgado',
    borrower: 'Assinou como comodatário',
    guarantorSpouse: 'Assinou como cônjuge do fiador',
    legalGuardian: 'Assinou como responsável legal',
    director: 'Assinou como diretor(a)',
    realEstateBroker: 'Assinou como corretor de imóveis',
    insuranceBroker: 'Assinou como corretor de seguros',
    licensor: 'Assinou como licenciante',
    licensed: 'Assinou como licenciada',
    serviceProvider: 'Assinou como prestador(a) de serviços',
    secured: 'Assinou como afiançado',
    consented: 'Assinou como anuído',
    interveningGuarantor: 'Assinou como interveniente garantidor',
    consentingIntervenor: 'Assinou como interveniente anuente',
    surveyor: 'Assinou como vistoriador',
    buildingManager: 'Assinou como síndico(a)',
    intermediary: 'Assinou como intermediário(a)',
    condominiumMember: 'Assinou como condômino',
    owner: 'Assinou como proprietário(a)',
    resident: 'Assinou como morador(a)',
    treasurer: 'Assinou como tesoureiro(a)',
    secretary: 'Assinou como secretário(a)',
  },
  signAs: {
    sign: 'Assinar',
    witness: 'Assinar como testemunha',
    party: 'Assinar como parte',
    intervening: 'Assinar como interveniente',
    receipt: 'Assinar para acusar recebimento',
    approve: 'Assinar para aprovar',
    endorser: 'Assinar como endossante',
    endorsee: 'Assinar como endossatário',
    administrator: 'Assinar como administrador',
    guarantor: 'Assinar como avalista',
    transferor: 'Assinar como cedente',
    transferee: 'Assinar como cessionário',
    contractee: 'Assinar como contratada',
    contractor: 'Assinar como contratante',
    jointDebtor: 'Assinar como devedor solidário',
    issuer: 'Assinar como emitente',
    manager: 'Assinar como gestor',
    buyer: 'Assinar como parte compradora',
    seller: 'Assinar como parte vendedora',
    attorney: 'Assinar como procurador',
    legalRepresentative: 'Assinar como representante legal',
    coResponsible: 'Assinar como responsável solidário',
    validator: 'Assinar como validador',
    surety: 'Assinar como fiador',
    lessor: 'Assinar como locador',
    lessee: 'Assinar como locatário',
    ratify: 'Assinar para homologar',
    employer: 'Empregador',
    employee: 'Empregado',
    consenting: 'Anuente',
    accountant: 'Contador',
    debtor: 'Devedor',
    franchisee: 'Franqueado',
    creditor: 'Credor',
    franchisor: 'Franqueador',
    insured: 'Segurado',
    grantor: 'Outorgante',
    president: 'Presidente',
    partner: 'Sócio',
    comforter: 'Comodante',
    accountHolder: 'Correntista',
    distracted: 'Distratada',
    distracting: 'Distratante',
    lender: 'Mutuante',
    associate: 'Associado',
    broker: 'Corretor',
    bailee: 'Fiel depositário',
    collateralProvider: 'Assinar como caucionante',
    pledged: 'Assinar como consignado',
    consignee: 'Assinar como consignatário',
    lawyer: 'Assinar como advogado',
    grantee: 'Assinar como outorgado',
    borrower: 'Assinar como comodatário',
    guarantorSpouse: 'Assinar como cônjuge do fiador',
    legalGuardian: 'Assinar como responsável legal',
    director: 'Assinar como diretor(a)',
    realEstateBroker: 'Assinar como corretor de imóveis',
    insuranceBroker: 'Assinar como corretor de seguros',
    licensor: 'Assinar como licenciante',
    licensed: 'Assinar como licenciada',
    serviceProvider: 'Assinar como prestador(a) de serviços',
    secured: 'Assinar como afiançado',
    consented: 'Assinar como anuído',
    interveningGuarantor: 'Assinar como interveniente garantidor',
    consentingIntervenor: 'Assinar como interveniente anuente',
    surveyor: 'Assinar como vistoriador',
    buildingManager: 'Assinar como síndico(a)',
    intermediary: 'Assinar como intermediário(a)',
    condominiumMember: 'Assinar como condômino',
    owner: 'Assinar como proprietário(a)',
    resident: 'Assinar como morador(a)',
    treasurer: 'Assinar como tesoureiro(a)',
    secretary: 'Assinar como secretário(a)',
  },
};

export default signer;

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useModal, useToast } from '@base/hooks';
import { useI18n } from '@/locales';
import { FormFlowEmptyList, FormFlowCreateFolderModal } from '@flow/components/form-flow';
import { useStore } from '@/store';

const { isModalActive, openModal, closeModal } = useModal();
const toast = useToast();
const { t } = useI18n();
const store = useStore();

const isLoading = ref(false);

const folders = computed(() => store.getters['flowFolder/getFolders']);
const pagination = computed(() => store.getters['flowFolder/getPagination']);
const totalItems = computed(() => store.getters['flowFolder/getTotalItems']);

const HEADERS = [
  {
    label: t('flowModule.folders.table.name'),
    value: 'name',
    colspan: 6,
  },
  {
    label: t('flowModule.folders.table.flowNumber'),
    value: 'flowsCount',
  },
  {
    label: t('flowModule.folders.table.createdAt'),
    value: 'createdAt',
  },
  {
    label: t('flowModule.folders.table.actions'),
    value: 'action',
    colspan: 2,
  },
];

const fetchFlowFolders = async () => {
  isLoading.value = true;
  await store.dispatch('flowFolder/fetchFlowFolders', { self: window.location.href });
  isLoading.value = false;
};

const handleErrorResponse = (err) => {
  const errors = err.response?.data?.flowFolder?.errors;

  if (errors) {
    Object.values(errors).forEach((value) => toast.error(value[0]));
  } else {
    toast.error(t('flowModule.folders.create.errorMessage'));
  }
};

const handleCreate = async ({ name }) => {
  try {
    await store.dispatch('flowFolder/createFlowFolder', { name });
    fetchFlowFolders();
    toast.success(t('flowModule.folders.create.successMessage'));
    closeModal();
  } catch (err) {
    handleErrorResponse(err);
  }
};

onMounted(async () => {
  await fetchFlowFolders();
});
</script>

<template>
  <div>
    <XCard>
      <div :class="$style.actions">
        <span :class="$style.spanItems">
          {{ $tc('flowModule.folders.table.folderLength', totalItems, { count: totalItems }) }}
        </span>
        <XButton
          size="sm"
          design="outlined"
          data-testid="newFolderButton"
          @click="openModal('FormFlowCreateFolderModal')"
        >
          {{ $t('flowModule.folders.table.newFolder') }}
          <XIcon
            icon="folder-plus"
            right
          />
        </XButton>
      </div>
      <XTable
        v-if="folders.length || isLoading"
        :headers="HEADERS"
        :items="folders"
        :loading="isLoading"
        data-testid="foldersTable"
      />
      <FormFlowEmptyList
        v-else
        icon="folder"
        :title="$t('flowModule.folders.emptyList.title')"
        :description="$t('flowModule.folders.emptyList.description')"
        :action-text="$t('flowModule.folders.emptyList.actionText')"
        action-design="outlined"
        data-testid="formFlowEmptyList"
        @handle-new="openModal('FormFlowCreateFolderModal')"
      />

      <XPagination
        v-if="pagination?.pageCount > 1"
        :class="$style.containerPagination"
        align="center"
        :active-page="pagination?.page"
        :total-pages="pagination?.pageCount"
        :next-text="$t('general.action.next')"
        :prev-text="$t('general.action.previous')"
        data-testid="containerPagination"
      />
    </XCard>

    <FormFlowCreateFolderModal
      :show-modal="isModalActive('FormFlowCreateFolderModal')"
      data-testid="createFolderModal"
      @close="closeModal"
      @create="handleCreate"
    />
  </div>
</template>

<style lang="scss" module>
.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space-medium);

  .spanItems {
    color: var(--color-neutral-700);
  }
}

.containerPagination {
  margin-top: var(--space-regular);
}
</style>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from '@/store';
import { useRouter, useRoute, useGA } from '@base/hooks';
import { debounce, isEqual } from 'lodash';
import moment from 'moment';
import { camelizeKeys } from '@base/utils';

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const router = useRouter();
const route = useRoute();

const maxDate = moment().format('YYYY-MM-DD');

const filterValues = ref({
  dateRange: {},
  flows: [],
  search: '',
});
const filterIsOpen = ref(false);
const dateTimePickerIsOpen = ref(false);

const flowsFilter = computed(() => store.getters['pendingAnswers/getFlowsFilter']);
const filterFlowItems = computed(() => {
  return flowsFilter.value.map(({ name, id }) => ({ text: name, value: id }));
});
const flowFormIdParam = computed(() => route.query.flow_form_id || route.query.flowFormId || []);
const dateRangeParam = computed(() => ({
  start: route.query.start_date || route.query.startDate,
  end: route.query.end_date || route.query.endDate,
}));
const textSearchParam = computed(() => route.query.text_search || route.query.textSearch || '');
const flowFormIdNumber = computed(() => flowFormIdParam.value.map((item) => +item));
const filterHasNotChanged = computed(
  () =>
    isEqual(flowFormIdNumber.value, filterValues.value.flows) &&
    dateRangeParam.value.start === filterValues.value.dateRange.start &&
    dateRangeParam.value.end === filterValues.value.dateRange.end
);
const formattedDateRange = computed(() => {
  const { start, end } = filterValues.value.dateRange;
  const formattedStartDate = start && moment(start).format('DD/MM/YYYY');
  const formattedEndDate = end ? moment(end).format('DD/MM/YYYY') : '...';
  const hasDateSelected = !!start || !!end;

  return hasDateSelected ? `${formattedStartDate} - ${formattedEndDate}` : '';
});

const filterAllowedQueryParams = (queryParams) => {
  const { textSearch, flowFormId, startDate, endDate } = camelizeKeys(queryParams);

  return { textSearch, flowFormId, startDate, endDate };
};

const handleSearch = () => {
  if (filterValues.value.search.trim() === textSearchParam.value.trim()) return;

  router.replace({
    query: {
      ...filterAllowedQueryParams(route.query),
      textSearch: filterValues.value.search.trim(),
    },
  });
};

const clearFilters = () => {
  filterValues.value.flows = [];
  filterValues.value.dateRange = {};
};

const applyFilters = () => {
  useGA('event', 'form-filter-click', 'automation-forms-pendencies-app');
  router.push({
    query: {
      ...filterAllowedQueryParams(route.query),
      flowFormId: filterValues.value.flows,
      startDate: filterValues.value.dateRange.start,
      endDate: filterValues.value.dateRange.end,
    },
  });
};

const debouncedSearch = debounce(handleSearch, 400);

onMounted(() => {
  filterValues.value = {
    dateRange: dateRangeParam.value,
    flows: flowFormIdNumber.value,
    search: textSearchParam.value,
  };
});

watch(() => filterValues.value.search, debouncedSearch);
</script>

<template>
  <div
    :class="$style.filterContainer"
    data-testid="pendingAnswersFilters"
  >
    <XTextInput
      v-model="filterValues.search"
      :placeholder="$t('pendingAnswers.filters.search')"
      full-width
      autofocus
      grouped
      data-testid="pendingAnswersFiltersTextSearch"
      @keyup.native.enter="handleSearch"
    >
      <XButton
        model="plain"
        @click="handleSearch"
      >
        <XIcon icon="search" />
      </XButton>
    </XTextInput>

    <XDropdown
      :is-open="filterIsOpen"
      :offset="4"
      placement="bottom-end"
      :close-on-click-content="false"
    >
      <template #activator="{ on }">
        <XButton
          size="sm"
          design="outlined"
          data-testid="pendingAnswersFiltersOpenFilters"
          :disabled="loading"
          @click="on"
        >
          {{ $t('general.filters') }}

          <XIcon
            icon="filter"
            right
          />
        </XButton>
      </template>
      <XCard
        :class="[$style.filters, dateTimePickerIsOpen && $style.minHeight]"
        elevation="high"
      >
        <h5>{{ $t('general.filters') }}</h5>

        <XSelect
          v-model="filterValues.flows"
          :class="$style.selectFlows"
          :items="filterFlowItems"
          :label="$t('pendingAnswers.filters.flows')"
          full-width
          multiple
          searchable
          inner-dropdown
        />

        <XDateTimePicker
          v-model="filterValues.dateRange"
          :locale="$i18n.locale"
          :max-date="maxDate"
          format="YYYY-MM-DD"
          no-header
          no-label
          no-shortcuts
          no-value-to-custom-elem
          only-date
          range
          @is-shown="dateTimePickerIsOpen = true"
          @is-hidden="dateTimePickerIsOpen = false"
        >
          <XTextInput
            :value="formattedDateRange"
            :placeholder="$t('pendingAnswers.filters.dateRangePlaceholder')"
            readonly
            label="Período"
            full-width
            grouped
          >
            <XButton model="plain">
              <XIcon icon="calendar-alt" />
            </XButton>
          </XTextInput>
        </XDateTimePicker>

        <div :class="$style.actions">
          <XButton
            design="outlined"
            size="sm"
            data-testid="pendingAnswersFiltersClearFilters"
            @click="clearFilters"
          >
            {{ $t('pendingAnswers.filters.clear') }}
          </XButton>

          <XButton
            size="sm"
            :disabled="filterHasNotChanged"
            data-testid="pendingAnswersFiltersApplyFilters"
            @click="applyFilters"
          >
            {{ $t('pendingAnswers.filters.apply') }}
          </XButton>
        </div>
      </XCard>
    </XDropdown>
  </div>
</template>

<style lang="scss" module>
.filterContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: var(--space-medium);
  column-gap: var(--space-regular);
}

.filters {
  width: 80vw;
  max-width: 430px;

  .selectFlows {
    margin-bottom: var(--space-regular);
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: var(--space-regular);
    column-gap: var(--space-small-xx);
  }

  @media (max-width: 415px) {
    &.minHeight {
      min-height: 385px;
    }
  }
}
</style>

import { sentry } from '@/plugins/sentry';
import { useVerifyLocation } from '@account/composables';

export function blockForeignUserGuard(to, from, next) {
  try {
    const { isForeignUser } = useVerifyLocation();
    if (isForeignUser.value) return next({ name: 'operatorBlockedRegion' });
  } catch (error) {
    sentry.captureException(error);
  }
  return next();
}

<script setup>
import { useRoute, useRouter, useToggle } from '@base/hooks';
import { computed, onMounted, ref } from 'vue';

defineEmits(['handle-new']);

const router = useRouter();
const route = useRoute();
// TOGGLE_NEW_LISTING_FLOW_ENABLED
// TODO: Remove line below
const { isEnabled: toggleNewListingFlowEnabled } = useToggle('toggle_new_listing_flow_enabled');

const name = ref('');

const nameParam = computed(() => route.query.name || '');
const nameHasNotChanged = computed(() => nameParam.value === name.value);
const isNameEmpty = computed(() => name.value === '');

const updateFilterRouter = () => {
  router.push({ query: { name: name.value } });
};

const clearFilter = () => {
  name.value = '';
  if (nameParam.value !== '') {
    router.push({ query: {} });
  }
};

onMounted(() => {
  name.value = nameParam.value;
});
</script>

<template>
  <header :class="$style.header">
    <div :class="$style.contentTitle">
      <h1
        :class="$style.title"
        data-testid="pageTitle"
      >
        {{ $t('flowModule.title') }}

        <!-- TOGGLE_FLOW_SIMPLIFIED_JOURNEY_ENABLED -->
        <!-- TODO: Remove the whole FeatureFlag block below -->
        <FeatureFlag name="toggleFlowSimplifiedJourneyEnabled">
          <XBadge
            small
            color="var(--color-feedback-info-400)"
            text-color="var(--color-neutral-0)"
            icon="gem"
            default="Beta"
          >
            {{ $t('general.feature.beta') }}
          </XBadge>
        </FeatureFlag>
      </h1>
      <div :class="$style.actions">
        <XLink
          icon="question-circle"
          size="small"
          :to="$t('links.help.flows.withForm')"
          target="_blank"
          data-ga-event="link-help-flows-click"
        >
          {{ $t('base.help') }}
        </XLink>
        <XButton
          data-testid="createFlowButton"
          data-ga-event="button-create-flow-click"
          @click="$emit('handle-new')"
        >
          {{ $t('flowModule.new') }}
        </XButton>
      </div>
    </div>
    <!-- TOGGLE_NEW_LISTING_FLOW_ENABLED -->
    <!-- TODO: Remove the whole block below -->
    <div
      v-if="!toggleNewListingFlowEnabled"
      :class="$style.filterContainer"
    >
      <XFormField
        :label="$t('flowModule.header.filter.label')"
        flexible
      >
        <div :class="$style.filter">
          <XTextInput
            v-model="name"
            full-width
            :placeholder="$t('flowModule.header.filter.placeholder')"
            data-testid="filterInput"
          />
        </div>
        <XButton
          design="link"
          size="sm"
          :disabled="isNameEmpty"
          data-testid="clearButton"
          @click="clearFilter"
        >
          {{ $t('flowModule.header.filter.clear') }}
        </XButton>
        <XButton
          design="outlined"
          size="sm"
          data-testid="filterButton"
          :disabled="nameHasNotChanged"
          @click="updateFilterRouter"
        >
          {{ $t('flowModule.header.filter.button') }}
        </XButton>
      </XFormField>
    </div>
  </header>
</template>

<style lang="scss" module>
.header {
  margin-bottom: var(--space-medium);

  .contentTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--space-regular);

    .title {
      display: flex;
      align-items: center;
      margin: 0;
      gap: var(--space-small-xx);
      color: var(--color-brand-primary-600);
    }

    .actions {
      display: flex;
    }
  }

  // TOGGLE_NEW_LISTING_FLOW_ENABLED

  // TODO: Remove .filterContainer and .filter classes

  .filterContainer {
    padding: var(--space-small-xx);
    background-color: var(--color-neutral-50);

    .filter {
      width: 303px;
    }
  }
}
</style>

import { getViewProps } from '@base/utils/getViewDataset';

const routes = [
  {
    path: '/profile',
    // SIGNERS_CONTACT_IMPROVEMENTS
    // TODO: Add /operator route alias if /operator exists on backend
    // TODO: alias: '/operator',
    component: () => import('@users/views/UserProfileView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      refresh: () => true,
    },
    props: () => ({
      ...getViewProps(),
    }),
  },
];

export default routes;

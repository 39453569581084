<script>
import { sendHubspotEvent } from '@base/utils/hubspot';
import { mapGetters, mapMutations } from 'vuex';
import useHotjar from '@/modules/base/hooks/useHotjar';
import PurchaseAutomationPlanModal from '@flow/components/purchase/PurchaseAutomationPlanModal.vue';
import modalsMixin from '@base/mixins/modals-mixin';

export default {
  name: 'FlowDisabledContainer',
  components: {
    PurchaseAutomationPlanModal,
  },
  mixins: [modalsMixin],
  data() {
    return {
      user: {},
      submitedData: false,
      loading: false,
      items: {
        whatsapp: 'whatsapp',
        automation: 'cog',
        append: 'paperclip',
        platform: 'shield-check',
      },
    };
  },
  computed: {
    ...mapGetters({
      links: 'formFlow/getLinks',
    }),
  },
  methods: {
    ...mapGetters('info', ['getUser', 'getAccount']),
    ...mapMutations({ setLinks: 'disabledFlow/SET_LINKS' }),
    buildUserData() {
      const user = this.getUser();
      const accountName = this.getAccount()?.name;
      this.user = Object.assign(_.clone(user), { accountName, documentAmount: '' });
    },
    openModal(mode) {
      this.buildUserData();
      this.modalActive = mode;
    },
    async send(formUserData) {
      this.loading = true;
      try {
        await sendHubspotEvent('hubspotFluxiaRequestFormId', {
          contact: {
            email: formUserData.email,
            phone: formUserData.phoneNumber,
            account_id: this.getAccount()?.id,
            documents_amount: formUserData.documentsAmount,
          },
        });

        this.submitedData = true;
      } catch (error) {
        if (error.response.status === 401) {
          this.$toast.error(this.$t('disabledFlow.purchase.error'));
          return;
        }
      } finally {
        this.loading = false;
      }
    },
    startHotjar() {
      useHotjar('event', 'flowPurchaseModal');
      this.openModal('purchaseFluxia');
    },
  },
};
</script>

<template>
  <XCard
    :class="$style.container"
    elevation="low"
  >
    <div :class="$style.banner">
      <img
        :class="$style.image"
        src="@flow/assets/images/disabled-flow/logo-clicksign-automacao.png"
      />
      <div :class="$style.containerMessage">
        <h3 :class="$style.message">
          {{ $t('disabledFlow.banner.message') }}
          <span :class="$style.highlight">
            {{ $t('disabledFlow.banner.highlight') }}
          </span>
        </h3>
        <p :class="$style.pricing">{{ $t('disabledFlow.banner.pricing') }}</p>
      </div>
      <div>
        <XButton @click="startHotjar">
          {{ $t('disabledFlow.button.text') }}
          <XIcon
            :class="$style.icon"
            right=""
            icon="arrow-right"
          />
        </XButton>
        <p :class="$style.info">
          {{ $t('disabledFlow.banner.info') }}
        </p>
      </div>
    </div>
    <h4 :class="$style.title">
      {{ $t('disabledFlow.info.title') }}
    </h4>
    <div :class="$style.cardContainer">
      <XCard
        v-for="(item, key) of items"
        :key="key"
        :class="$style.cardItem"
        outlined
      >
        <XIcon
          :icon="item"
          :icon-style="item === 'whatsapp' ? 'fab' : 'fal'"
          size="2x"
          :class="$style.icon"
        />
        <h5>
          {{ $t(`disabledFlow.info.items.${key}.title`) }}
        </h5>
        <span>
          {{ $t(`disabledFlow.info.items.${key}.content`) }}
        </span>
      </XCard>
    </div>
    <div :class="$style.containerButton">
      <XButton
        design="outlined"
        @click="startHotjar"
      >
        {{ $t('disabledFlow.button.text') }}
        <XIcon
          :class="$style.icon"
          right=""
          icon="arrow-right"
        />
      </XButton>
      <p :class="$style.info">
        {{ $t('disabledFlow.banner.info') }}
      </p>
    </div>
    <PurchaseAutomationPlanModal
      :loading="loading"
      :submited-data="submitedData"
      :user="user"
      :value="isModalActive('purchaseFluxia')"
      @send-user="send($event)"
      @close-modal="closeModal"
    />
  </XCard>
</template>

<style lang="scss" module>
@import 'app/assets/stylesheets/mixins/button-color';

.container {
  margin: var(--space-regular);
}

.banner {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: var(--space-large);
  gap: var(--space-large);
  background-color: var(--color-brand-support-03-400);

  .image {
    width: 137px;
  }

  .containerMessage {
    max-width: 216px;
    text-align: center;

    @include breakpoint('large') {
      max-width: 344px;
      text-align: left;
    }
  }

  .message {
    font-weight: var(--font-weight-bold);
  }

  .pricing {
    color: var(--color-transparent-1000-medium);
  }

  .info {
    margin-top: var(--space-small-xx);
    font-size: var(--font-size-small-x);
    color: var(--color-neutral-1000);
  }

  .highlight {
    color: var(--color-brand-primary-400);
  }

  @include breakpoint('large') {
    align-items: flex-start;
  }
}

@include breakpoint('large') {
  .banner {
    background-image: url('@flow/assets/images/disabled-flow/ilustracao-automacao.png');
    background-repeat: no-repeat;
    background-position: top var(--space-large) right var(--space-large);
  }
}

.title {
  margin: var(--space-large) 0;
  text-align: center;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(autofill, 1fr);
  gap: var(--space-regular);

  @include breakpoint('medium') {
    grid-template-columns: repeat(2, 1fr);
  }
}

.cardItem {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: var(--space-large);
  gap: var(--space-regular);

  .icon {
    color: var(--color-brand-primary-400);
  }
}

.containerButton {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: var(--space-large);

  .info {
    margin-top: var(--space-small-xx);
    font-size: var(--font-size-small-x);
    color: var(--color-neutral-1000);
  }
}
</style>

// TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
// TODO: Remove usTrialExperiment import and constant
import { useTrialExperiment } from '@base/composables';

const { isTrialExperiment } = useTrialExperiment();

const routes = [
  {
    path: '/accounts/:accountId/dashboard',
    name: 'dashboard',
    component: () =>
      // TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
      // TODO: Remove ternary and keep DashboardView import
      isTrialExperiment.value
        ? import('@dashboard/views/DashboardSimplifiedView/DashboardSimplifiedView.vue')
        : import('@dashboard/views/DashboardView.vue'),
    meta: {
      layout: 'BaseNavigationLayout',
      additionalDataSurveyTrackingId: 'dashboard',
    },
  },
  {
    path: '/accounts/:accountId/dashboard/document_details',
    name: 'dashboardDocumentDetails',
    component: () => import('@dashboard/views/DashboardDocumentsDetailsView.vue'),
    meta: {
      layout: 'BaseNavigationLayout',
    },
  },
];

export default routes;

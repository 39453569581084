import { axios } from '@/plugins';

const fetchFlowFolders = async ({ commit }, { self }) => {
  try {
    const { data } = await axios.get(self, {
      params: {
        perPage: 10,
      },
    });

    commit('SET_LINKS', data.links);
    commit('SET_PAGINATION', data.pagination);
    commit('SET_FOLDERS', data.flowFolders);
    commit('SET_TOTAL_ITEMS', data.totalItems);
  } catch (err) {
    throw err;
  }
};

const createFlowFolder = async ({ getters }, { name }) => {
  try {
    const url = getters.getLinks.createFolder;

    await axios.post(url, { name });
  } catch (err) {
    throw err;
  }
};

export default {
  fetchFlowFolders,
  createFlowFolder,
};

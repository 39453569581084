// TOGGLE_CUSTOM_TRIAL_EXPERIMENT_ENABLED
// TODO: Decide if composable is going to be removed after experiment
import { computed } from 'vue';

import { useStore } from '@/store';
import { useAccountInfo } from '@base/hooks';
import { useVerifyLocation } from '@account/composables';

const { isActiveTrial } = useAccountInfo();
const { isNationalUser } = useVerifyLocation();
const store = useStore();

export const useTrialExperiment = () => {
  const isSimplifiedExperimentActive = computed(
    () => store.getters['info/getABTests']?.customTrialExperiment === 'simplified'
  );

  const isTrialExperiment = computed(
    () => isActiveTrial.value && isNationalUser.value && isSimplifiedExperimentActive.value
  );

  return { isTrialExperiment, isSimplifiedExperimentActive };
};

import { getViewProps } from '@base/utils/getViewDataset';
import { blockForeignUserGuard } from '@operators/routes/navigation-guards/block-foreign-users/index';

const routes = [
  {
    beforeEnter: blockForeignUserGuard,
    path: '/signer',
    component: () => import('@signer-area/layouts/SignerAreaLayout.vue'),
    redirect: '/signer/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'SignerAreaDashboard',
        component: () => import('@signer-area/views/SignerAreaDashboardView.vue'),
        props: getViewProps,
        meta: {
          refresh: () => true,
          layout: 'BaseCleanLayout',
        },
      },
      {
        path: 'profile',
        component: () => import('@users/views/UserProfileView.vue'),
        props: getViewProps,
        meta: {
          refresh: () => true,
          layout: 'BaseCleanLayout',
        },
      },
      {
        path: 'preview/envelopes/:envelopeKey',
        component: () => import('@signer-area/views/SignerAreaEnvelopePreviewerView.vue'),
        children: [
          {
            path: '',
            name: 'SignerAreaEnvelopeDetails',
            component: () =>
              import(
                '@signer-area/containers/SignerEnvelopeDetailsContainer/SignerEnvelopeDetailsContainer.vue'
              ),
          },
          {
            path: 'documents/:documentKey',
            name: 'SignerAreaDocumentDetails',
            component: () =>
              import(
                '@signer-area/containers/SignerDocumentDetailsContainer/SignerDocumentDetailsContainer.vue'
              ),
          },
        ],
      },
    ],
  },
];

export default routes;
